<template>
    <div class="input-group" :class="{error: hasError}">
        <label v-if="label" :for="identifier">{{label}}</label>
        <Select :id="identifier" :value="value" className="input" @input="(v)=>$emit('input', v)"
                :placeholder="placeholder" :disabled="disabled" :options="options" :multiple="multiple"
                :close-on-select="closeOnSelect" :allow-empty="allowEmpty" :label="displayLabel"
                :custom-label="displayCustomLabel" :track-by="trackBy" @updated="()=>$emit('updated')"
                :taggable="taggable" @tag="(newTag)=>$emit('tag', newTag)"/>
        <FormErrors v-if="hasError">
            <slot name="errors"></slot>
        </FormErrors>
    </div>
</template>

<script>
    import FormErrors from "./FormErrors";
    import Select from "./Select";

    export default {
        name: "FormInputSelect",
        components: {Select, FormErrors},
        props: {
            value: {
                type: [String, Object, Array],
            },
            identifier: {
                type: String,
                default: null,
            },
            options: {
                type: Array,
                default: () => ([])
            },
            label: {
                type: String,
                default: null,
            },
            displayLabel: {
                type: String,
            },
            displayCustomLabel: {
                default: null,
            },
            trackBy: {
                type: String,
            },
            placeholder: {
                type: String,
                default: null
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            closeOnSelect: {
                type: Boolean,
                default: true,
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            taggable: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style lang="scss" scoped>
    .input-group {
        @apply w-full flex flex-col items-start mb-5;

        label {
            @apply text-sm text-black font-bold mb-3;
        }

        &.error {
            .input {
                @apply border-negative;
            }
        }
    }
</style>