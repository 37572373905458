<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{$t('properties.add_property')}}</h1>
            </template>
            <template v-slot:right>
              <Button v-if="$store.getters.hasPermission('update properties')" class="btn-save-desktop" className="--primary --small" :onclick="save"
                      :class="{spinner: is_saving}">
                {{$t('save')}}
              </Button>
              <button class="btn-save-mobile btn-icon-only" @click="save"
                      :class="{spinner: is_saving}">
                <font-awesome-icon :icon="['far', 'save']"/>
              </button>
            </template>
        </Headbar>
        <main>
            <div class="form-area">
                <Form class="form">
                    <SectionHeader :title="$t('properties.property_details')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.property.address.$model" identifier="property_address"
                                           :label="$t('properties.address')"
                                           :placeholder="$t('properties.address')" :disabled="is_saving" :has-error="$v.property.address.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.address.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.address')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputSelect v-model="$v.property.locality.$model" identifier="property_locality"
                                             :label="$t('properties.locality')"
                                             :options="localityOptions" :placeholder="$t('properties.locality')"
                                             :disabled="is_saving"
                                             track-by="id" :display-custom-label="(row)=>row.attributes.name" :has-error="$v.property.locality.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.locality.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.locality')})}}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputText v-model="property.post_code" identifier="property_post_code"
                                           :label="$t('properties.post_code')"
                                           :placeholder="$t('properties.post_code')" :disabled="is_saving">
                            </FormInputText>
                            <FormInputText v-model="property.google_maps_link" identifier="property_google_maps_link"
                                           :label="$t('properties.google_maps_link')"
                                           :placeholder="$t('properties.google_maps_link')" :disabled="is_saving">
                            </FormInputText>
                            <FormInputDateTime v-model="property.available_at" identifier="available_at"
                                               :label="$t('properties.available_from')"
                                               :only-date="true" formatted="Do MMM YYYY"
                                               :placeholder="$t('properties.date')"
                                               :disabled="is_saving" output-format="YYYY-MM-DD">
                            </FormInputDateTime>
                            <FormInputSelect v-model="$v.property.condition.$model" identifier="property_condition"
                                             :label="$t('properties.property_condition')"
                                             :options="conditionOptions" track-by="column"
                                             display-label="name"
                                             :placeholder="$t('properties.property_condition')" :disabled="is_saving" :has-error="$v.property.condition.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.condition.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.property_condition')})}}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputSelect v-model="$v.property.type.$model" identifier="property_type"
                                             :label="$t('properties.property_type')" track-by="column"
                                             :options="typeOptions" :placeholder="$t('properties.property_type')"
                                             :disabled="is_saving" display-label="name" :has-error="$v.property.type.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.type.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.property_type')})}}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputSelect v-model="$v.property.owner.$model" identifier="property_owner"
                                             :label="$t('properties.property_owner')" :multiple="true"
                                             :options="ownerOptions" :placeholder="$t('properties.property_owner')"
                                             :disabled="is_saving" track-by="id"
                                             :display-custom-label="(row)=>row.attributes.name" :has-error="$v.property.owner.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.owner.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.property_owner')})}}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputText v-model="$v.property.monthly_rent.$model" identifier="monthly_rent"
                                           :label="$t('properties.monthly_rent')"
                                           :placeholder="$t('properties.monthly_rent')" :disabled="is_saving" :has-error="$v.property.monthly_rent.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.monthly_rent.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.monthly_rent')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.property.deposit.$model" identifier="deposit"
                                           :label="$t('properties.deposit')"
                                           :placeholder="$t('properties.deposit')" :disabled="is_saving" :has-error="$v.property.deposit.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.deposit.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.deposit')})}}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupThree>
                        <FormInputText v-model="$v.property.notes.$model" identifier="notes"
                                       :label="$t('properties.notes')" :useTextarea="true"
                                       :placeholder="$t('properties.notes')" :disabled="is_saving" :has-error="$v.property.notes.$error">
                        </FormInputText>
                    </div>
                </Form>
                <Form class="form">
                    <SectionHeader :title="$t('properties.property_attributes')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.property.amenities.floor.$model" identifier="floor"
                                           :label="$t('properties.floor')"
                                           :placeholder="$t('properties.floor')" :disabled="is_saving" :has-error="$v.property.amenities.floor.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.amenities.floor.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.floor')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.property.amenities.bedrooms.$model" identifier="bedrooms"
                                           :label="$t('properties.bedrooms')"
                                           :placeholder="$t('properties.bedrooms')" :disabled="is_saving" :has-error="$v.property.amenities.bedrooms.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.amenities.bedrooms.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.bedrooms')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.property.amenities.sleeps.$model" identifier="sleeps"
                                           :label="$t('properties.sleeps')"
                                           :placeholder="$t('properties.sleeps')" :disabled="is_saving" :has-error="$v.property.amenities.sleeps.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.amenities.sleeps.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.sleeps')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.property.amenities.bathrooms.$model" identifier="bathrooms"
                                           :label="$t('properties.bathrooms')"
                                           :placeholder="$t('properties.bathrooms')" :disabled="is_saving" :has-error="$v.property.amenities.bathrooms.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.amenities.bathrooms.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.bathrooms')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.property.amenities.air_conditioner.$model" identifier="air_conditioner"
                                           :label="$t('properties.air_conditioner')"
                                           :placeholder="$t('properties.air_conditioner')" :disabled="is_saving" :has-error="$v.property.amenities.air_conditioner.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.property.amenities.air_conditioner.required">
                                        {{$t('validation.x_is_required',{x: $t('properties.air_conditioner')})}}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupThree>
                        <div class="checkboxes-container">
                            <p-check v-model="property.amenities.kitchen_living_dining" class="p-icon p-curve"
                                     color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Kitchen/living/dining
                            </p-check>

                            <p-check v-model="property.amenities.duplex" class="p-icon p-curve"
                                     color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Duplex
                            </p-check>

                            <p-check v-model="property.amenities.dish_washer" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Dish washer
                            </p-check>

                            <p-check v-model="property.amenities.utility_room" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Utility room
                            </p-check>

                            <p-check v-model="property.amenities.seaview" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Sea view
                            </p-check>

                            <p-check v-model="property.amenities.seafront" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Sea front
                            </p-check>

                            <p-check v-model="property.amenities.balcony" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Balconies
                            </p-check>

                            <p-check v-model="property.amenities.intercom" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Intercom
                            </p-check>

                            <p-check v-model="property.amenities.open_plan" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Open plan
                            </p-check>

                            <p-check v-model="property.amenities.television" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Televisions
                            </p-check>

                            <p-check v-model="property.amenities.ceiling_fans" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Ceiling fans
                            </p-check>

                            <p-check v-model="property.amenities.study_room" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Study room
                            </p-check>

                            <p-check v-model="property.amenities.desk" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Desks
                            </p-check>

                            <p-check v-model="property.amenities.roof" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Roof
                            </p-check>

                            <p-check v-model="property.amenities.washing_machine" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Washing machine
                            </p-check>

                            <p-check v-model="property.amenities.tumble_dryer" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Tumble dryer
                            </p-check>

                            <p-check v-model="property.amenities.lift" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Lift
                            </p-check>

                            <p-check v-model="property.amenities.pool" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Pool
                            </p-check>

                            <p-check v-model="property.amenities.jacuzzi" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Jacuzzi
                            </p-check>

                            <p-check v-model="property.amenities.garden_yard" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Garden/yard
                            </p-check>

                            <p-check v-model="property.amenities.wine_cooler" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Wine cooler
                            </p-check>

                            <p-check v-model="property.amenities.internet" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Internet
                            </p-check>

                            <p-check v-model="property.amenities.pet_friendly" class="p-icon p-curve" color="dark">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Pet friendly
                            </p-check>
                        </div>
                    </div>
                </Form>
                <Form class="form">
                    <SectionHeader :title="$t('properties.images')"></SectionHeader>
                    <div class="form-body">
                        <div v-if="property.attachments.length" class="manage-upload">
                            <div class="images-container">
                                <div v-for="(attachment, index) in property.attachments" class="image-wrapper">
                                    <div class="image-container">
                                        <button type="button" class="btn-delete" @click="removeAttachment(index)">
                                            <font-awesome-icon :icon="['far','times']"/>
                                        </button>
                                        <img :src="attachment.preview"/>
                                    </div>
                                </div>
                            </div>
                            <div class="upload-row">
                                <label for="property-create-file-upload">
                                    <font-awesome-icon :icon="['fal','upload']"/>
                                    <p>{{$t('upload')}}</p>
                                </label>
                            </div>
                        </div>
                        <div v-else class="upload">
                            <label for="property-create-file-upload">
                                <font-awesome-icon :icon="['fal','upload']"/>
                                <p>{{$t('upload')}}</p>
                            </label>
                        </div>

                        <input type="file" name="file" id="property-create-file-upload" accept="image/*" multiple
                               @change="handleFileChange" :disabled="is_saving"/>
                    </div>
                </Form>
            </div>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Form from "../../components/form/Form";
    import SectionHeader from "../../components/SectionHeader";
    import FormGroupThree from "../../components/form/FormGroupThree";
    import FormInputText from "../../components/form/FormInputText";
    import {required} from 'vuelidate/lib/validators'
    import FormInputSelect from "../../components/form/FormInputSelect";
    import FormInputDateTime from "../../components/form/FormInputDateTime";
    import Button from "../../components/Button";

    export default {
        name: "properties-create-page",
        components: {
            Button,
            FormGroupThree, FormInputSelect, FormInputText, FormInputDateTime, SectionHeader, Form, Headbar
        },
        data() {
            return {
                property: {
                    address: null,
                    post_code: null,
                    google_maps_link: null,
                    type: null,
                    condition: null,
                    monthly_rent: null,
                    deposit: null,
                    owner: null,
                    locality: null,
                    available_at: null,
                    amenities: {
                        floor: null,
                        bedrooms: null,
                        sleeps: null,
                        bathrooms: null,
                        air_conditioner: null,
                        kitchen_living_dining: false,
                        duplex: false,
                        dish_washer: false,
                        utility_room: false,
                        seaview: false,
                        seafront: false,
                        balcony: false,
                        intercom: false,
                        open_plan: false,
                        television: false,
                        ceiling_fans: false,
                        study_room: false,
                        desk: false,
                        roof: false,
                        washing_machine: false,
                        tumble_dryer: false,
                        lift: false,
                        pool: false,
                        jacuzzi: false,
                        garden_yard: false,
                        wine_cooler: false,
                        internet: false,
                        pet_friendly: false,
                    },
                    notes: null,
                    attachments: []
                },
                ownerOptions: [],
                localityOptions: [],
                conditionOptions: [],
                typeOptions: [],
                totalRecords: null,
                is_saving: false,
                is_loading_owner_options: false,
                is_loading_locality_options: false,
                is_loading_condition_options: false,
                is_loading_type_options: false,
            }
        },
        validations: {
            property: {
                address: {required},
                post_code: {},
                type: {required},
                condition: {required},
                monthly_rent: {required},
                deposit: {required},
                owner: {required},
                locality: {required},
                available_at: {},
                amenities: {
                    floor: {required},
                    bedrooms: {required},
                    sleeps: {required},
                    bathrooms: {required},
                    air_conditioner: {required}
                },
                notes: {}
            }
        },
        methods: {
            removeAttachment(index) {
                if (index < 0 || index >= this.property.attachments.length)
                    return;

                this.property.attachments.splice(index, 1);
            },
            retrieveLocalityOptions() {
                this.is_loading_locality_options = false;
                this.$axios.get('localities/list')
                    .then(({data}) => {
                        this.is_loading_locality_options = false;
                        this.localityOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_locality_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('localities.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveOwnerOptions() {
                this.is_loading_owner_options = false;
                this.$axios.get('owners/list')
                    .then(({data}) => {
                        this.is_loading_owner_options = false;
                        this.ownerOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_owner_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveConditionOptions() {
                this.is_loading_condition_options = false;
                this.$axios.get('properties/list-conditions')
                    .then(({data}) => {
                        this.is_loading_condition_options = false;
                        this.conditionOptions = data;
                    })
                    .catch(e => {
                        this.is_loading_condition_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveTypeOptions() {
                this.is_loading_type_options = false;
                this.$axios.get('properties/list-types')
                    .then(({data}) => {
                        this.is_loading_type_options = false;
                        this.typeOptions = data;
                    })
                    .catch(e => {
                        this.is_loading_type_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            handleFileChange(e) {
                const files = e.target.files;

                Array.from(files).forEach(async file => {
                    this.property.attachments.push({
                        file: file,
                        preview: await this.getBase64FromFile(file),
                        name: file.name,
                        id: null,
                    });
                });
            },
            getBase64FromFile(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            uploadFile(property_id, attachment, name) {
                let formData = new FormData();
                formData.append('name', name);
                formData.append('document', attachment);

                return this.$axios.post(`properties/${property_id}/documents`, formData);
            },
            save() {
                this.$v.property.$touch();
                if (this.$v.property.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.property.$model);
                payload.locality_id = this.$v.property.locality.$model.id;
                payload.condition = this.$v.property.condition.$model.column;
                payload.type = this.$v.property.type.$model.column;

                delete payload.attachments;

                if(this.property.post_code === null || this.property.post_code === "") {
                    delete payload.post_code;
                }

                if(this.property.notes === null || this.property.notes === "") {
                    delete payload.notes;
                }

                this.$axios.post(`properties`, payload).then(async ({data}) => {
                    const property_id = data.data.id;

                    if (this.$v.property.owner.$model.length) {
                        this.$axios.put(`properties/${property_id}/owners/sync`, {
                            owners: this.$v.property.owner.$model.map(o => o.id)
                        }).catch(e => {
                            this.$notify({
                                title: this.$t('error'),
                                text: this.$t('properties.error_save_owners'),
                                type: 'error',
                            });
                        });
                    }

                    if (this.property.attachments.length) {
                        const uploadPromises = [];

                        this.property.attachments.forEach(attachment => {
                            uploadPromises.push(this.uploadFile(property_id, attachment.file, attachment.name));
                        });

                        await Promise.all(uploadPromises).then(() => {
                            this.$notify({
                                text: this.$t('properties.success_created'),
                                type: 'success',
                            });

                            this.is_saving = false;
                        }).catch(e => {
                            this.$notify({
                                title: this.$t('error'),
                                text: this.$t('error_upload_files'),
                                type: 'error',
                            });
                        });

                        this.$router.push({name: 'properties-index'});
                    } else {
                        this.$notify({
                            text: this.$t('properties.success_created'),
                            type: 'success',
                        });

                        this.is_saving = false;

                        this.$router.push({name: 'properties-index'});
                    }
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('properties.error_create')),
                        type: 'error',
                    });
                });
            },
            head() {
                return {
                    title: {
                        inner: this.$t('properties.add_property')
                    },
                }
            }
        },
        mounted() {
            this.retrieveLocalityOptions();
            this.retrieveOwnerOptions();
            this.retrieveConditionOptions();
            this.retrieveTypeOptions();
        },
    }
</script>

<style lang="scss" scoped>
    .page-container {
        main {
            @apply px-0;

            .form-area {
                @apply flex flex-col flex-wrap px-9;

                @screen xl {
                    @apply flex-row;
                }

                .form-column {
                    @apply flex flex-col w-full;

                    @screen xl {
                        @apply mr-4;

                        width: calc(50% - 1rem);
                    }

                    .form {
                        @apply w-full mx-0;
                    }
                }

                .form {
                    @apply mb-8 w-full;

                    @screen xl {
                        @apply ml-4;

                        width: calc(50% - 1rem);
                    }

                    .form-body {
                        @apply px-8 py-7;

                        .checkboxes-container {
                            @apply flex flex-row flex-wrap;

                            .pretty {
                                @apply mb-4 mr-72;

                                @screen md{
                                    @apply mr-10;
                                }
                            }
                        }

                        .manage-upload {
                            .images-container {
                                @apply flex flex-row flex-wrap -m-3;

                                .image-wrapper {
                                    @apply p-3 w-1/4;

                                    .image-container {
                                        @apply border-2 border-grey-light rounded p-2 relative;

                                        img {
                                            @apply rounded;
                                        }

                                        .btn-delete {
                                            @apply border-2 border-grey-light rounded flex flex-row items-center justify-center h-5 w-5 absolute right-0 top-0 bg-white;

                                            margin-top: -0.625rem;
                                            margin-right: -0.625rem;

                                            svg {
                                                @apply text-black;

                                                font-size: .7rem;
                                            }

                                            &:active, &:focus {
                                                @apply outline-none;
                                            }
                                        }
                                    }
                                }
                            }

                            .upload-row {
                                @apply flex flex-row justify-end mt-5;

                                label {
                                    @apply py-4 px-7 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                                    svg {
                                        @apply text-black text-xl;
                                    }

                                    p {
                                        @apply text-black font-bold text-sm ml-3;
                                    }
                                }
                            }
                        }

                        .upload {
                            label {
                                @apply flex flex-col items-center;

                                svg {
                                    @apply text-primary mb-4;

                                    font-size: 4.5rem;
                                }

                                p {
                                    @apply text-black font-bold text-xl;
                                }
                            }
                        }

                        input[type="file"] {
                            @apply hidden;
                        }
                    }

                    .row-add-container {
                        @apply px-8;
                    }
                }
            }

            .divider {
                @apply w-full border-b-2 border-primary;
            }

            .table-area {
                @apply px-9 py-7;
            }
        }

        .btn-save-mobile {
          @apply block;

          @screen md {
            @apply hidden;
          }
        }

        .btn-save-desktop {
          @apply hidden;

          @screen md {
            @apply block;
          }
        }
    }
</style>