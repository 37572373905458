<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button  v-if="$store.getters.hasPermission('store attributes')"
                    className="--primary --small" :onclick="toggleCreate">
                    {{$t('attributes.add_attribute')}}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('attributes.search_attributes')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only" @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('attributes.search_attributes')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table striped vgt-custom"
                :columns="columns"
                :rows="attributes"
                :isLoading.sync="is_loading_attributes"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    setCurrentPage: 1,
                    perPage: 15,
                    perPageDropdown: [15],
                    perPageDropdownEnabled: false,
                    dropdownAllowAll: false,
                    rowsPerPageLabel: this.$t('x_per_page', {x: this.$t('attributes.attributes')}),
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'date'">

                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'attributes-update', params: {id: props.row.id}}"
                                     v-if="$store.getters.hasAnyPermission(['read attributes', 'update attributes'])">
                            <Button className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="$store.getters.hasAnyPermission(['destroy attributes'])"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Button from "../../components/Button";
    import Search from "../../components/Search";
    import AttributesCreateModal from "../../components/attributes/AttributesCreateModal";

    export default {
        name: "attributes-index-page",
        components: {Search, Button, Headbar},
        data() {
            const columns =  [
                    {
                        label: this.$t('attributes.name'),
                        field: 'attributes.name',
                        sortable: false,
                    },
                    {
                        label: this.$t('attributes.type'),
                        field: 'attributes.type',
                        sortable: false,
                    }
                ]

            if (this.$store.getters.hasAnyPermission(['read attributes', 'update attributes', 'destroy attributes']))
                columns.push({
                    label: this.$t('attributes.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                })

            return {
                columns: columns,
                attributes: [],
                is_loading_attributes: false,
                totalRecords: null,
                serverParams: {},
                searchTerm: null,
                is_deleting: false,
                headbarExpanded: null,
            }
        },
        methods: {
            toggleCreate() {
                this.$modal.show(
                    AttributesCreateModal, {},
                    {
                        name: 'attributes-create-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveAttributes();
                        }
                    }
                );
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveAttributes();
            },
            onPerPageChange(params) {
                this.updateParams({per_page: params.currentPerPage, page: 1});
                this.retrieveAttributes();
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.retrieveAttributes();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm, retrieve = true, resetPage = true) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                if (resetPage) {
                    this.serverParams.page = 1;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: 1});
                    this.$router.push({query: {...this.$route.query, term: this.searchTerm, page: 1}});
                }

                if(retrieve)
                    this.retrieveAttributes();
            },
            retrieveAttributes() {
                this.is_loading_attributes = true;

                this.$axios.get('attributes', {params: this.serverParams})
                    .then(({data}) => {
                        this.attributes = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_attributes = false;
                    })
                    .catch(e => {
                        this.is_loading_attributes = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('attributes.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },
        mounted() {
            if (this.$route.query) {
                if (this.$route.query.page) {
                    this.serverParams.page = this.$route.query.page;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: +this.$route.query.page});
                }

                if (this.$route.query.term) {
                    this.searchTerm = this.$route.query.term;
                    this.search(this.$route.query.term, false, false);
                }
            }

            this.retrieveAttributes();
        },
        watch:{
            $route (){
                this.search(this.$route.query.term, true, false);
            }
        },
        head() {
            return {
                title: {
                    inner: this.$t('nav.attributes')
                },
            }
        }

    }
</script>

<style lang="scss" scoped>
    .page-container {
        .headbar-expanded-container {
            @apply mt-4 flex flex-col gap-y-4 items-end;

            @screen md {
                @apply hidden;
            }
        }

        .btn-search-mobile, .btn-filter-mobile {
            @apply block;

            @screen md {
                @apply hidden;
            }

            &:active,&:focus{
                @apply outline-none;
            }
        }

        .search-desktop, .filter-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }

        .filter-mobile {
            @apply max-w-xs;
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>
