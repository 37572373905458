<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{original.attributes.reference}}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --small" :onclick="()=>toggleExport($route.params.id)">
                    {{$t('properties.export')}}
                </Button>
                <Button v-if="original && $store.getters.hasRole('administrator')" className="--primary --small"
                        :onclick="verify">
                    <template v-if="!original.attributes.verified_at">
                        {{$t('properties.verify')}}
                    </template>
                    <template v-else>
                        {{$t('properties.unverify')}}
                    </template>
                </Button>
                <Button v-if="$store.getters.hasPermission('store rents')" className="--primary --small"
                        :onclick="toggleCreate">
                    {{$t('properties.add_rent')}}
                </Button>
                <Button v-if="$store.getters.hasPermission('update properties')" class="btn-save-desktop"
                        className="--primary --small" :onclick="save"
                        :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
                <button v-if="$store.getters.hasPermission('update properties')"
                        class="btn-save-mobile btn-icon-only" @click="save"
                        :class="{spinner: is_saving}">
                    <font-awesome-icon :icon="['far', 'save']"/>
                </button>
            </template>
        </Headbar>
        <main>
            <div class="form-area">
                <Form class="form" :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                    <SectionHeader :title="$t('properties.property_details')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.property.address.$model" identifier="property_address"
                                           :label="$t('properties.address')"
                                           :placeholder="$t('properties.address')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputSelect v-model="$v.property.locality.$model" identifier="property_locality"
                                             :label="$t('properties.locality')"
                                             :options="localityOptions" :placeholder="$t('properties.locality')"
                                             :disabled="!$store.getters.hasPermission('update properties') || is_saving"
                                             track-by="id" :display-custom-label="(row)=>row.attributes.name">
                            </FormInputSelect>
                            <FormInputText v-model="property.post_code" identifier="property_post_code"
                                           :label="$t('properties.post_code')"
                                           :placeholder="$t('properties.post_code')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="property.google_maps_link" identifier="property_google_maps_link"
                                           :label="$t('properties.google_maps_link')"
                                           :placeholder="$t('properties.google_maps_link')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputDateTime v-model="property.available_at" identifier="available_at"
                                               :label="$t('properties.available_from')"
                                               :only-date="true" formatted="Do MMM YYYY"
                                               :placeholder="$t('properties.date')"
                                               :disabled="!$store.getters.hasPermission('update properties') || is_saving"
                                               output-format="YYYY-MM-DD">
                            </FormInputDateTime>
                            <FormInputSelect v-model="$v.property.condition.$model" identifier="property_condition"
                                             :label="$t('properties.property_condition')"
                                             :options="conditionOptions" track-by="column"
                                             display-label="name"
                                             :placeholder="$t('properties.property_condition')"
                                             :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputSelect>
                            <FormInputSelect v-model="$v.property.type.$model" identifier="property_type"
                                             :label="$t('properties.property_type')" track-by="column"
                                             :options="typeOptions" :placeholder="$t('properties.property_type')"
                                             :disabled="!$store.getters.hasPermission('update properties') || is_saving"
                                             display-label="name">
                            </FormInputSelect>
                            <FormInputSelect v-model="$v.property.owner.$model" identifier="property_owner"
                                             :label="$t('properties.property_owner')" :multiple="true"
                                             :options="ownerOptions" :placeholder="$t('properties.property_owner')"
                                             :disabled="!$store.getters.hasPermission('update properties') || is_saving"
                                             track-by="id"
                                             :display-custom-label="(row)=>row.attributes.name">
                            </FormInputSelect>
                            <FormInputText v-model="$v.property.phone.$model" identifier="property_owner_phone"
                                           :label="$t('owners.contact_number')"
                                           :placeholder="$t('owners.contact_number')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="$v.property.monthly_rent.$model" identifier="monthly_rent"
                                           :label="$t('properties.monthly_rent')"
                                           :placeholder="$t('properties.monthly_rent')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="$v.property.deposit.$model" identifier="deposit"
                                           :label="$t('properties.deposit')"
                                           :placeholder="$t('properties.deposit')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                        </FormGroupThree>
                        <FormInputText v-model="$v.property.notes.$model" identifier="notes"
                                       :label="$t('properties.notes')" :useTextarea="true"
                                       :placeholder="$t('properties.notes')" :disabled="is_saving"
                                       :has-error="$v.property.notes.$error">
                        </FormInputText>
                    </div>
                </Form>
                <Form class="form">
                    <SectionHeader :title="$t('properties.property_attributes')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="property.amenities.floor" identifier="floor"
                                           :label="$t('properties.floor')"
                                           :placeholder="$t('properties.floor')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="property.amenities.bedrooms" identifier="bedrooms"
                                           :label="$t('properties.bedrooms')"
                                           :placeholder="$t('properties.bedrooms')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="property.amenities.sleeps" identifier="sleeps"
                                           :label="$t('properties.sleeps')"
                                           :placeholder="$t('properties.sleeps')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="property.amenities.bathrooms" identifier="bathrooms"
                                           :label="$t('properties.bathrooms')"
                                           :placeholder="$t('properties.bathrooms')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="property.amenities.air_conditioner" identifier="air_conditioner"
                                           :label="$t('properties.air_conditioner')"
                                           :placeholder="$t('properties.air_conditioner')"
                                           :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                            </FormInputText>
                        </FormGroupThree>
                        <div class="checkboxes-container">
                            <p-check v-model="property.amenities.kitchen_living_dining" class="p-icon p-curve"
                                     color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Kitchen/living/dining
                            </p-check>

                            <p-check v-model="property.amenities.duplex" class="p-icon p-curve"
                                     color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Duplex
                            </p-check>

                            <p-check v-model="property.amenities.dish_washer" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Dish washer
                            </p-check>

                            <p-check v-model="property.amenities.utility_room" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Utility room
                            </p-check>

                            <p-check v-model="property.amenities.seaview" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Sea view
                            </p-check>

                            <p-check v-model="property.amenities.seafront" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Sea front
                            </p-check>

                            <p-check v-model="property.amenities.balcony" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Balconies
                            </p-check>

                            <p-check v-model="property.amenities.intercom" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Intercom
                            </p-check>

                            <p-check v-model="property.amenities.open_plan" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Open plan
                            </p-check>

                            <p-check v-model="property.amenities.television" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Televisions
                            </p-check>

                            <p-check v-model="property.amenities.ceiling_fans" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Ceiling fans
                            </p-check>

                            <p-check v-model="property.amenities.study_room" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Study room
                            </p-check>

                            <p-check v-model="property.amenities.desk" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Desk
                            </p-check>

                            <p-check v-model="property.amenities.roof" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Roof
                            </p-check>

                            <p-check v-model="property.amenities.washing_machine" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Washing machine
                            </p-check>

                            <p-check v-model="property.amenities.tumble_dryer" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Tumble dryer
                            </p-check>

                            <p-check v-model="property.amenities.lift" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Lift
                            </p-check>

                            <p-check v-model="property.amenities.pool" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Pool
                            </p-check>

                            <p-check v-model="property.amenities.jacuzzi" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Jacuzzi
                            </p-check>

                            <p-check v-model="property.amenities.garden_yard" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Garden/yard
                            </p-check>

                            <p-check v-model="property.amenities.wine_cooler" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Wine cooler
                            </p-check>

                            <p-check v-model="property.amenities.internet" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Internet
                            </p-check>

                            <p-check v-model="property.amenities.pet_friendly" class="p-icon p-curve" color="dark"
                                     :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                <font-awesome-icon class="icon mdi mdi-check" slot="extra"
                                                   :icon="['far', 'check']"/>
                                Pet friendly
                            </p-check>
                        </div>
                    </div>
                </Form>
                <Form class="form">
                    <SectionHeader :title="$t('properties.images')"></SectionHeader>
                    <div class="form-body">
                        <div v-if="property.attachments.length" class="manage-upload">
                            <div class="images-container">
                                <div v-for="(attachment, index) in property.attachments" class="image-wrapper">
                                    <div class="image-container">
                                        <button type="button" class="btn-delete" @click="removeAttachment(index)"
                                                :disabled="!$store.getters.hasPermission('update properties') || is_saving">
                                            <font-awesome-icon :icon="['far','times']"/>
                                        </button>
                                        <img :src="attachment.preview"/>
                                    </div>
                                </div>
                            </div>
                            <div class="upload-row">
                                <label for="property-update-file-upload">
                                    <font-awesome-icon :icon="['fal','upload']"/>
                                    <p>{{$t('upload')}}</p>
                                </label>
                            </div>
                        </div>
                        <div v-else class="upload">
                            <label for="property-update-file-upload">
                                <font-awesome-icon :icon="['fal','upload']"/>
                                <p>{{$t('upload')}}</p>
                            </label>
                        </div>

                        <input type="file" name="file" id="property-update-file-upload" accept="image/*" multiple
                               @change="handleFileChange"
                               :disabled="!$store.getters.hasPermission('update properties') || is_saving"/>
                    </div>
                </Form>
            </div>

            <div class="table-area">
                <vue-good-table
                        styleClass="vgt-table striped vgt-custom"
                        :columns="columns"
                        :rows="property_rents"
                        :isLoading.sync="is_loading_property_rents"
                        :search-options="{
                            enabled: false,
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            dropdownAllowAll: false,
                            rowsPerPageLabel: $t('x_per_page', {x: $t('properties.entries')}),
                        }"
                >
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'after'" class="td-after">
                            <button v-if="!props.row.relationships.contracts.data.filter(c=>c.attributes.type === 'final').length"
                                    class="btn-upload" @click="toggleRentUploadFinalContract(props.row)">
                                <font-awesome-icon :icon="['fal','upload']"/>
                                <p>{{$t('upload')}}</p>
                            </button>
                            <Button v-if="$store.getters.hasAnyPermission(['read rents', 'update rents'])"
                                    className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleRentDetails(props.row)">
                                <font-awesome-icon :icon="['far', 'eye']"/>
                            </Button>
                            <Button v-if="$store.getters.hasPermission('destroy rents')"
                                    className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleRentDelete(props.row)">
                                <font-awesome-icon :icon="['fal', 'trash']"/>
                            </Button>
                        </div>
                        <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                    </template>
                </vue-good-table>
            </div>

            <div class="divider"></div>

            <div class="audits-table">
                <header>
                    <h3>{{$t('properties.audits')}}</h3>
                </header>
                <vue-good-table
                        mode="remote"
                        styleClass="vgt-table striped vgt-custom"
                        :columns="auditColumns"
                        :rows="audits"
                        :isLoading.sync="is_loading_audits"
                        :search-options="{
                enabled: false,
            }"
                        :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('properties.audits')}),
            }"
                        :totalRows="totalAuditRecords"
                        @on-page-change="onAuditPageChange"
                        @on-sort-change="onAuditSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'values'" class="td-values">
                            <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                <p class="name">{{$t('properties.old_values')}}</p>
                                <div class="values">
                                    <div v-for="key in Object.keys(props.row.attributes.old_values)">
                                        <p v-if="key !== 'amenities'">
                                            <strong>{{$t(`properties.${key}`)}}:</strong>
                                            {{props.row.attributes.old_values[key]}}
                                        </p>
                                        <div v-else-if="key === 'amenities' && props.row.attributes.old_values[key] && props.row.attributes.old_values[key].length">
                                            <p v-for="amenity in Object.keys(JSON.parse(props.row.attributes.old_values[key]))">
                                                <strong>{{$t(`properties.${amenity}`)}}:</strong>
                                                {{JSON.parse(props.row.attributes.old_values[key])[amenity]}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                <p class="name">{{$t('properties.new_values')}}</p>
                                <div class="values">
                                    <div v-for="key in Object.keys(props.row.attributes.new_values)">
                                        <p v-if="key !== 'amenities'">
                                            <strong>{{$t(`properties.${key}`)}}:</strong>
                                            {{props.row.attributes.new_values[key]}}
                                        </p>
                                        <div v-else-if="key === 'amenities' && props.row.attributes.new_values[key] && props.row.attributes.new_values[key].length">
                                            <p v-for="amenity in Object.keys(JSON.parse(props.row.attributes.new_values[key]))">
                                                <strong>{{$t(`properties.${amenity}`)}}:</strong>
                                                {{JSON.parse(props.row.attributes.new_values[key])[amenity]}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Form from "../../components/form/Form";
    import _ from 'lodash';
    import SectionHeader from "../../components/SectionHeader";
    import FormInputText from "../../components/form/FormInputText";
    import {required} from 'vuelidate/lib/validators'
    import FormGroupThree from "../../components/form/FormGroupThree";
    import FormInputSelect from "../../components/form/FormInputSelect";
    import FormInputDateTime from "../../components/form/FormInputDateTime";
    import Button from "../../components/Button";
    import RentCreateModal from "../../components/properties/RentCreateModal";
    import ConfirmModal from "../../components/modal/ConfirmModal";
    import RentUploadFinalContractModal from "../../components/properties/RentUploadFinalContractModal";
    import RentDetailsModal from "../../components/properties/RentDetailsModal";
    import {EventBus} from "../../EventBus";
    import PropertyModal from "../../components/modal/PropertyModal";

    export default {
        name: "properties-update-page",
        components: {
            Button, ConfirmModal,
            FormInputDateTime, FormInputSelect, FormGroupThree, FormInputText, SectionHeader, Form, Headbar
        },
        data: function () {
            const columns = [
                {
                    label: this.$t('properties.rent_id'),
                    field: row => `${this.$t('properties.rent')} ${row.id}`,
                    sortable: false,
                },
                {
                    label: this.$t('properties.client'),
                    field: row => `${row.attributes.client_name}`,
                    sortable: false,
                },
                {
                    label: this.$t('properties.starts_at'),
                    field: row => this.$moment.utc(row.attributes.starts_at).local().format('DD/MM/YYYY'),
                    sortable: false,
                },
                {
                    label: this.$t('properties.ends_at'),
                    field: row => this.$moment.utc(row.attributes.ends_at).local().format('DD/MM/YYYY'),
                    sortable: false,
                },
                {
                    label: this.$t('properties.agent'),
                    field: row => `${row.attributes.agent_name}`,
                    sortable: false,
                }
            ];

            const auditColumns = [
                {
                    label: this.$t('properties.user'),
                    field: 'relationships.user.data.attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('properties.event'),
                    field: row => this.$t(`properties.${row.attributes.event}`),
                    sortable: false,
                },
                {
                    label: this.$t('properties.date_n_time'),
                    field: row => this.$moment.utc(row.attributes.created_at).local().format('DD/MM/YYYY - HH:mm'),
                    sortable: false,
                },
                {
                    label: this.$t('properties.values'),
                    field: 'values',
                    sortable: false,
                }
            ];

            if (this.$store.getters.hasAnyPermission(['read rents', 'store rents', 'update rents', 'destroy rents']))
                columns.push({
                    label: this.$t('properties.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                });

            return {
                original: null,
                columns: columns,
                auditColumns: auditColumns,

                property: {
                    address: null,
                    post_code: null,
                    google_maps_link: null,
                    type: null,
                    condition: null,
                    monthly_rent: null,
                    deposit: null,
                    owner: null,
                    phone: null,
                    locality: null,
                    available_at: null,
                    amenities: {
                        floor: null,
                        bedrooms: null,
                        sleeps: null,
                        bathrooms: null,
                        air_conditioner: null,
                        kitchen_living_dining: false,
                        duplex: false,
                        dish_washer: false,
                        utility_room: false,
                        seaview: false,
                        seafront: false,
                        balcony: false,
                        intercom: false,
                        open_plan: false,
                        television: false,
                        ceiling_fans: false,
                        study_room: false,
                        desk: false,
                        roof: false,
                        washing_machine: false,
                        tumble_dryer: false,
                        lift: false,
                        pool: false,
                        jacuzzi: false,
                        garden_yard: false,
                        wine_cooler: false,
                        internet: false,
                        pet_friendly: false,
                    },
                    notes: null,
                    attachments: []
                },

                attachmentsToDelete: [],
                ownerOptions: [],
                localityOptions: [],
                conditionOptions: [],
                typeOptions: [],
                property_rents: [],
                property_owner: [],
                serverParams: {},
                audits: [],
                totalAuditRecords: null,
                is_saving: false,
                is_loading_audits: false,
                is_loading_owner_options: false,
                is_loading_locality_options: false,
                is_loading_condition_options: false,
                is_loading_type_options: false,
                is_loading_property_rents: false,
                is_loading_property_documents: false,
                is_loading_original: false,
            }
        },
        validations: {
            property: {
                address: {required},
                post_code: {},
                type: {required},
                condition: {required},
                monthly_rent: {required},
                deposit: {required},
                owner: {required},
                phone: {required},
                locality: {required},
                available_at: {},
                notes: {}
            }
        },
        methods: {
            verify() {
                this.$axios.put(`properties/${this.$route.params.id}/toggle-verify`)
                    .then(({data}) => {
                        this.original.attributes.verified_at = data.data.attributes.verified_at;
                    });
            },
            async toggleExport(property_id) {
                let property = {};

                await this.$axios.get(`properties/${property_id}`)
                    .then(({data}) => {
                        property = data.data;
                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });

                let attributes = "";

                if (property.attributes.amenities.floor)
                    if(property.attributes.amenities.floor == 1)
                        attributes += "" + property.attributes.amenities.floor + " floor<br/>";
                    else
                        attributes += "" + property.attributes.amenities.floor + " floors<br/>";

                if (property.attributes.amenities.bedrooms)
                    if(property.attributes.amenities.bedrooms == 1)
                        attributes += "" + property.attributes.amenities.bedrooms + " bedroom<br/>";
                    else
                        attributes += "" + property.attributes.amenities.bedrooms + " bedrooms<br/>";

                if (property.attributes.amenities.bathrooms)
                    if(property.attributes.amenities.bathrooms == 1)
                        attributes += "" + property.attributes.amenities.bathrooms + " bathroom<br/>";
                    else
                        attributes += "" + property.attributes.amenities.bathrooms + " bathrooms<br/>";

                if (property.attributes.amenities.sleeps)
                    if(property.attributes.amenities.sleeps == 1)
                        attributes += "" + property.attributes.amenities.sleeps + " sleep<br/>";
                    else
                        attributes += "" + property.attributes.amenities.sleeps + " sleeps<br/>";

                if (property.attributes.amenities.air_conditioner)
                    if(property.attributes.amenities.air_conditioner == 1)
                        attributes += "" + property.attributes.amenities.air_conditioner + " air conditioner<br/>";
                    else
                        attributes += "" + property.attributes.amenities.air_conditioner + " air conditioners<br/>";

                if(property.attributes.amenities.floor || property.attributes.amenities.bedrooms || property.attributes.amenities.bathrooms || property.attributes.amenities.sleeps || property.attributes.amenities.air_conditioner)
                    attributes += "<br/>";

                if (property.attributes.amenities.air_conditioner)
                    attributes += "-air conditioning<br/>";

                if (property.attributes.amenities.kitchen_living_dining)
                    attributes += "-kitchen/living/dining<br/>";

                if (property.attributes.amenities.duplex)
                    attributes += "-duplex<br/>";

                if (property.attributes.amenities.dish_washer)
                    attributes += "-dish washer<br/>";

                if (property.attributes.amenities.utility_room)
                    attributes += "-utility room<br/>";

                if (property.attributes.amenities.seafront)
                    attributes += "-sea front<br/>";

                if (property.attributes.amenities.seaview)
                    attributes += "-sea view<br/>";

                if (property.attributes.amenities.balcony)
                    attributes += "-balconies<br/>";

                if (property.attributes.amenities.intercom)
                    attributes += "-intercom<br/>";

                if (property.attributes.amenities.open_plan)
                    attributes += "-open plan<br/>";

                if (property.attributes.amenities.television)
                    attributes += "-television<br/>";

                if (property.attributes.amenities.ceiling_fans)
                    attributes += "-ceiling fans<br/>";

                if (property.attributes.amenities.study_room)
                    attributes += "-study room<br/>";

                if (property.attributes.amenities.desk)
                    attributes += "-desk<br/>";

                if (property.attributes.amenities.roof)
                    attributes += "-roof<br/>";

                if (property.attributes.amenities.washing_machine)
                    attributes += "-washing machine<br/>";

                if (property.attributes.amenities.tumble_dryer)
                    attributes += "-tumble dryer<br/>";

                if (property.attributes.amenities.lift)
                    attributes += "-lift<br/>";

                if (property.attributes.amenities.pool)
                    attributes += "-pool<br/>";

                if (property.attributes.amenities.jacuzzi)
                    attributes += "-jacuzzi<br/>";

                if (property.attributes.amenities.garden_yard)
                    attributes += "-garden/yard<br/>";

                if (property.attributes.amenities.wine_cooler)
                    attributes += "-wine cooler<br/>";

                if (property.attributes.amenities.internet)
                    attributes += "-internet included<br/>";

                if (property.attributes.amenities.pet_friendly)
                    attributes += "-pet friendly<br/>";

                this.$modal.show(
                    PropertyModal, {
                        id: property.id,
                        title: property.attributes.reference,
                        description: property.attributes.amenities.bedrooms + ' bedroom ' + property.attributes.type + ' in ' + property.relationships.locality.data.attributes.name,
                        agency_fees_text: 'NO AGENCY FEES',
                        reference: 'ID: ' + property.attributes.reference,
                        attributes: attributes,
                        date: 'Available from: ' + this.$moment.utc(property.attributes.available_at).local().format('Do MMM YYYY'),
                        deposit: 'Deposit: €' + property.attributes.deposit,
                        price: 'Price: €' + property.attributes.monthly_rent,
                    },
                    {
                        name: 'property-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal height-fix',
                    },
                )
            },
            toggleRentDelete(rent) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('properties.prompt_rent_delete'),
                        subtext: this.$t('properties.prompt_rent_delete_subtext'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.$axios.delete(`properties/${this.$route.params.id}/rents/${rent.id}`)
                                    .then(({data}) => {
                                        this.retrieveOriginalProperty();
                                    })
                                    .catch(e => {
                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('properties.error_delete_rent')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            toggleRentDetails(rent) {
                this.$modal.show(
                    RentDetailsModal, {
                        rent: rent,
                    },
                    {
                        name: 'rent-details-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            // if (e.params === true)
                            //     this.retrieveOriginalProperty();
                        }
                    }
                );
            },
            toggleRentUploadFinalContract(rent) {
                this.$modal.show(
                    RentUploadFinalContractModal, {
                        rent_id: rent.id,
                    },
                    {
                        name: 'rent-upload-final-contract-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveOriginalProperty();
                        }
                    }
                );
            },
            removeAttachment(index) {
                if (index < 0 || index >= this.property.attachments.length)
                    return;

                if (this.property.attachments[index].id)
                    this.attachmentsToDelete.push(this.property.attachments[index].id);

                this.property.attachments.splice(index, 1);
            },
            handleFileChange(e) {
                const files = e.target.files;

                Array.from(files).forEach(async file => {
                    this.property.attachments.push({
                        file: file,
                        preview: await this.getBase64FromFile(file),
                        name: file.name,
                    });
                });
            },
            getBase64FromFile(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            uploadFile(property_id, attachment, name) {
                let formData = new FormData();
                formData.append('name', name);
                formData.append('document', attachment);

                return this.$axios.post(`properties/${property_id}/documents`, formData);
            },
            deleteFile(property_id, id) {
                return this.$axios.delete(`properties/${property_id}/documents/${id}`);
            },
            retrieveAudits() {
                this.is_loading_audits = false;

                this.$axios.get(`properties/${this.$route.params.id}/audits`)
                    .then(({data}) => {
                        this.audits = data.data;
                        this.is_loading_audits = false;
                    })
                    .catch(e => {
                        this.is_loading_audits = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('organisations.error_retrieve_audits')),
                            type: 'error',
                        });
                    });
            },
            updateAuditParams(newProps) {
                this.auditServerParams = Object.assign({}, this.auditServerParams, newProps);
            },
            onAuditPageChange(params) {
                this.updateAuditParams({page: params.currentPage});
                this.retrieveAudits();
            },
            onAuditSortChange(params) {
                this.updateAuditParams({sort: params});
                this.retrieveAudits();
            },
            save() {
                this.$v.property.$touch();
                if (this.$v.property.$anyError || this.is_saving){
                    return;
                }

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.property.$model);
                payload.locality_id = this.$v.property.locality.$model.id;
                payload.condition = this.$v.property.condition.$model.column;
                payload.type = this.$v.property.type.$model.column;

                delete payload.attachments;

                this.$axios.patch(`properties/${this.$route.params.id}`, payload).then(async ({data}) => {
                    const property_id = this.$route.params.id;

                    this.$axios.put(`properties/${property_id}/owners/sync`, {
                        owners: this.$v.property.owner.$model.map(o => o.id)
                    }).catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('properties.error_save_owners'),
                            type: 'error',
                        });
                    });

                    if (this.property.attachments.filter(a => !a.id).length || this.attachmentsToDelete.length) {
                        const filePromises = [];

                        this.property.attachments.filter(a => !a.id).forEach(attachment => {
                            filePromises.push(this.uploadFile(property_id, attachment.file, attachment.name));
                        });

                        this.attachmentsToDelete.forEach(attachment => {
                            filePromises.push(this.deleteFile(property_id, attachment));
                        });

                        await Promise.all(filePromises).then(() => {
                            this.$notify({
                                text: this.$t('properties.success_updated'),
                                type: 'success',
                            });

                            this.is_saving = false;
                        }).catch(e => {
                            this.$notify({
                                title: this.$t('error'),
                                text: this.$t('error_save_files'),
                                type: 'error',
                            });
                        });

                        this.$router.push({name: 'properties-index'});
                    } else {
                        this.$notify({
                            text: this.$t('properties.success_updated'),
                            type: 'success',
                        });

                        this.is_saving = false;

                        this.$router.push({name: 'properties-index'});
                    }
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('properties.error_update')),
                        type: 'error',
                    });
                });
            },
            toggleCreate() {
                this.$modal.show(
                    RentCreateModal, {
                        property_id: this.$route.params.id,
                    },
                    {
                        name: 'rent-create-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveOriginalProperty();
                        }
                    }
                );
            },
            retrieveLocalityOptions() {
                this.is_loading_locality_options = false;
                this.$axios.get('localities/list')
                    .then(({data}) => {
                        this.is_loading_locality_options = false;
                        this.localityOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_locality_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('localities.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            async retrieveOwnerOptions() {
                this.is_loading_owner_options = false;
                await this.$axios.get('owners/list')
                    .then(({data}) => {
                        this.is_loading_owner_options = false;
                        this.ownerOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_owner_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveConditionOptions() {
                this.is_loading_condition_options = false;
                this.$axios.get('properties/list-conditions')
                    .then(({data}) => {
                        this.is_loading_condition_options = false;
                        this.conditionOptions = data;
                    })
                    .catch(e => {
                        this.is_loading_condition_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveTypeOptions() {
                this.is_loading_type_options = false;
                this.$axios.get('properties/list-types')
                    .then(({data}) => {
                        this.is_loading_type_options = false;
                        this.typeOptions = data;
                    })
                    .catch(e => {
                        this.is_loading_type_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            async retrieveOriginalProperty() {
                this.is_loading_original = false;
                await this.$axios.get(`properties/${this.$route.params.id}`)
                    .then(({data}) => {
                        this.original = data.data;
                        this.property_rents = this.original.relationships.rents.data;
                        this.property_owner = this.original.relationships.owners.data;
                        this.is_loading_original = false;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            async populate() {
                if (!this.original || !this.property)
                    return;

                this.is_loading_original = true;
                this.$v.property.address.$model = this.original.attributes.address;
                this.property.post_code = this.original.attributes.post_code;
                this.property.notes = this.original.attributes.notes;
                this.property.google_maps_link = this.original.attributes.google_maps_link;
                this.$v.property.monthly_rent.$model = this.original.attributes.monthly_rent.toString();
                this.$v.property.deposit.$model = this.original.attributes.deposit.toString();
                this.$v.property.available_at.$model = this.original.attributes.available_at;
                this.property.amenities.floor = this.original.attributes.amenities.floor;
                this.property.amenities.bedrooms = this.original.attributes.amenities.bedrooms;
                this.property.amenities.sleeps = this.original.attributes.amenities.sleeps;
                this.property.amenities.bathrooms = this.original.attributes.amenities.bathrooms;
                this.property.amenities.air_conditioner = this.original.attributes.amenities.air_conditioner;

                this.property.amenities.kitchen_living_dining = this.original.attributes.amenities.kitchen_living_dining;
                this.property.amenities.duplex = this.original.attributes.amenities.duplex;
                this.property.amenities.dish_washer = this.original.attributes.amenities.dish_washer;
                this.property.amenities.utility_room = this.original.attributes.amenities.utility_room;
                this.property.amenities.seaview = this.original.attributes.amenities.seaview;
                this.property.amenities.seafront = this.original.attributes.amenities.seafront;
                this.property.amenities.balcony = this.original.attributes.amenities.balcony;
                this.property.amenities.intercom = this.original.attributes.amenities.intercom;
                this.property.amenities.open_plan = this.original.attributes.amenities.open_plan;
                this.property.amenities.television = this.original.attributes.amenities.television;
                this.property.amenities.ceiling_fans = this.original.attributes.amenities.ceiling_fans;
                this.property.amenities.study_room = this.original.attributes.amenities.study_room;
                this.property.amenities.desk = this.original.attributes.amenities.desk;
                this.property.amenities.roof = this.original.attributes.amenities.roof;
                this.property.amenities.washing_machine = this.original.attributes.amenities.washing_machine;
                this.property.amenities.tumble_dryer = this.original.attributes.amenities.tumble_dryer;
                this.property.amenities.lift = this.original.attributes.amenities.lift;
                this.property.amenities.pool = this.original.attributes.amenities.pool;
                this.property.amenities.jacuzzi = this.original.attributes.amenities.jacuzzi;
                this.property.amenities.garden_yard = this.original.attributes.amenities.garden_yard;
                this.property.amenities.wine_cooler = this.original.attributes.amenities.wine_cooler;
                this.property.amenities.internet = this.original.attributes.amenities.internet;
                this.property.amenities.pet_friendly = this.original.attributes.amenities.pet_friendly;

                this.$v.property.condition.$model = _.find(this.conditionOptions, {column: this.original.attributes.condition});
                this.$v.property.type.$model = _.find(this.typeOptions, {column: this.original.attributes.type});
                this.$v.property.locality.$model = _.find(this.localityOptions, {id: this.original.relationships.locality.data.id});

                const relationshipOwnerIds = this.original.relationships.owners.data.map(o => o.id);
                this.$v.property.owner.$model = this.ownerOptions.filter(op => relationshipOwnerIds.includes(op.id));
                this.$v.property.phone.$model = this.property_owner[0].attributes.phone;
                this.is_loading_original = false;
            },
            retrievePropertyDocuments() {
                this.is_loading_property_documents = true;
                this.$axios.get(`properties/${this.$route.params.id}/documents`)
                    .then(({data}) => {
                        if (data.data.length) {
                            this.property.attachments = data.data.map(d => ({
                                id: d.id,
                                name: d.attributes.name,
                                preview: d.attributes.image_path,
                                file: null,
                            }));
                        }

                        this.is_loading_property_documents = false;
                    })
                    .catch(e => {
                        this.is_loading_property_documents = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_documents_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },

        async mounted() {
            this.retrieveLocalityOptions();
            await this.retrieveOwnerOptions();
            this.retrieveConditionOptions();
            this.retrieveTypeOptions();
            this.retrievePropertyDocuments();
            this.retrieveAudits();

            await this.retrieveOriginalProperty();
            await this.populate();

            EventBus.$on('updated-contract', this.retrieveOriginalProperty);
        },
        beforeDestroy() {
            EventBus.$off('updated-contract', this.retrieveOriginalProperty);
        },

        head() {
            return {
                title: {
                    inner: this.$t('properties.edit_property')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        main {
            @apply px-0;

            .form-area {
                @apply flex flex-col flex-wrap px-9;

                @screen xl {
                    @apply flex-row;
                }

                .form-column {
                    @apply flex flex-col w-full;

                    @screen xl {
                        @apply mr-4;

                        width: calc(50% - 1rem);
                    }

                    .form {
                        @apply w-full mx-0;
                    }
                }

                .form {
                    @apply mb-8 w-full;
                ;

                    @screen xl {
                        @apply ml-4;

                        width: calc(50% - 1rem);
                    }

                    .form-body {
                        @apply px-8 py-7;

                        .checkboxes-container {
                            @apply flex flex-row flex-wrap;

                            .pretty {
                                @apply mb-4 mr-72;

                                @screen md{
                                    @apply mr-10;
                                }
                            }
                        }

                        .manage-upload {
                            .images-container {
                                @apply flex flex-row flex-wrap -m-3;

                                .image-wrapper {
                                    @apply p-3 w-full;

                                    @screen sm {
                                        @apply w-1/3;
                                    }

                                    @screen md {
                                        @apply w-1/4;
                                    }

                                    .image-container {
                                        @apply border-2 border-grey-light rounded p-2 relative;

                                        img {
                                            @apply rounded;
                                        }

                                        .btn-delete {
                                            @apply border-2 border-grey-light rounded flex flex-row items-center justify-center h-8 w-8 absolute right-0 top-0 bg-white;

                                            margin-top: -1rem;
                                            margin-right: -1rem;

                                            @screen md {
                                                @apply h-5 w-5;

                                                margin-top: -0.625rem;
                                                margin-right: -0.625rem;
                                            }

                                            svg {
                                                @apply text-black;

                                                font-size: .7rem;
                                            }

                                            &:active, &:focus {
                                                @apply outline-none;
                                            }
                                        }
                                    }
                                }
                            }

                            .upload-row {
                                @apply flex flex-row justify-end mt-5;

                                label {
                                    @apply py-4 px-7 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                                    svg {
                                        @apply text-black text-xl;
                                    }

                                    p {
                                        @apply text-black font-bold text-sm ml-3;
                                    }
                                }
                            }
                        }

                        .upload {
                            label {
                                @apply flex flex-col items-center;

                                svg {
                                    @apply text-primary mb-4;

                                    font-size: 4.5rem;
                                }

                                p {
                                    @apply text-black font-bold text-xl;
                                }
                            }
                        }

                        input[type="file"] {
                            @apply hidden;
                        }
                    }

                    .row-add-container {
                        @apply px-8;
                    }
                }
            }

            .divider {
                @apply w-full border-b-2 border-primary;
            }

            .table-area {
                @apply px-9 py-7;

                .td-after {
                    @apply flex flex-row items-center;

                    .btn-upload {
                        @apply py-2 px-3 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                        svg {
                            @apply text-black text-lg;
                        }

                        p {
                            @apply text-black font-bold text-xs ml-3;
                        }
                    }

                    & > button {
                        @apply mx-1;
                    }
                }
            }

            .audits-table {
                @apply px-9 py-7;

                header {
                    @apply flex flex-row items-center mb-6;

                    h3 {
                        @apply text-black font-bold text-xl;
                    }

                    .actions {
                        @apply ml-auto;

                        & > * {
                            @apply mr-4;

                            &:last-child {
                                @apply mr-0;
                            }
                        }
                    }
                }

                .td-values {
                    @apply flex flex-col gap-y-4;

                    .row {
                        @apply flex flex-col gap-y-1;

                        p.name {
                            @apply text-sm font-bold text-black leading-normal;
                        }

                        .values {
                            @apply flex flex-col;

                            .row-value {

                            }
                        }
                    }
                }
            }
        }

        .btn-save-mobile {
            @apply block;

            @screen md {
                @apply hidden;
            }
        }

        .btn-save-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }
    }
</style>