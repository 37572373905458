<template>
    <div class="roles-create-modal">
        <ModalContainer :title="$t('roles.add_role')" identifier="roles-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.role.name.$model" identifier="name" :label="$t('roles.name')"
                               :placeholder="$t('roles.name')" :disabled="is_saving" :has-error="$v.role.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.role.name.required">
                            {{$t('validation.x_is_required',{x: $t('roles.name')})}}
                        </p>
                    </template>
                </FormInputText>
                <FormInputSelect v-model="$v.role.permission.$model" identifier="permission"
                                 :label="$t('roles.permissions')" :options="permissionOptions"
                                 :placeholder="$t('roles.permissions')" :multiple="true" :disabled="is_saving"
                                 :has-error="$v.role.permission.$error"
                                 :displayCustomLabel="(row) => row.attributes.name" track-by="id">
                    <template v-slot:errors>
                        <p v-if="!$v.role.permission.required">
                            {{$t('validation.x_is_required',{x: $t('roles.permissions')})}}
                        </p>
                    </template>
                </FormInputSelect>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormInputSelect from "../form/FormInputSelect";
    import {required} from 'vuelidate/lib/validators';
    import Button from "../Button";

    export default {
        name: "roles-create-modal",
        components: {Button, FormInputSelect, FormInputText, Form, ModalContainer},
        data() {
            return {
                role: {
                    name: null,
                    permission: null,
                },

                is_saving: false,
                is_loading_permissions: false,
                permissionOptions: [],
            }
        },
        validations: {
            role: {
                name: {required},
                permission: {required}
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('roles-create-modal', status);
            },
            save() {
                this.$v.role.$touch();
                if (this.$v.role.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                // const payload = Object.assign({}, this.$v.role.$model);

                const payload = {
                    name: this.role.name
                }

                this.$axios.post(`roles`, payload).then(async ({data}) => {
                    const role_id = data.data.id;

                    if (this.role.permission.length) {
                        this.role.permission.forEach(p => {
                            this.$axios.put(`roles/${role_id}/permissions/${p.id}/attach`);
                        })
                    }

                    this.close(true);

                    this.$notify({
                        text: this.$t('users.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('users.error_create')),
                        type: 'error',
                    });
                });
            },
            retrievePermissionOptions() {
                this.is_loading_permissions = false;
                this.$axios.get('permissions/list')
                    .then(({data}) => {
                        this.is_loading_permissions = false;
                        this.permissionOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_permissions = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        mounted() {
            this.retrievePermissionOptions();
        }
    }
</script>

<style scoped>

</style>