<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{original.attributes.name}}</h1>
            </template>
            <template v-slot:right>
              <Button v-if="$store.getters.hasPermission('update properties')" class="btn-save-desktop" className="--primary --small" :onclick="save"
                      :class="{spinner: is_saving}">
                {{$t('save')}}
              </Button>
              <button class="btn-save-mobile btn-icon-only" @click="save"
                      :class="{spinner: is_saving}">
                <font-awesome-icon :icon="['far', 'save']"/>
              </button>
            </template>
        </Headbar>
        <main>
            <div class="form-area">
                <Form class="form" :disabled="!$store.getters.hasPermission('update owners') || is_saving">
                    <SectionHeader :title="$t('properties.owner_details')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.owner.name.$model" identifier="owner_name"
                                           :label="$t('owners.full_name')"
                                           :placeholder="$t('owners.full_name')" :disabled="!$store.getters.hasPermission('update owners') || is_saving" :has-error="$v.owner.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.owner.name.required">
                                        {{$t('validation.x_is_required',{x: $t('owners.full_name')})}}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputText v-model="$v.owner.phone.$model" identifier="contact_number"
                                           :label="$t('owners.contact_number')"
                                           :placeholder="$t('owners.contact_number')" :disabled="!$store.getters.hasPermission('update owners') || is_saving" :has-error="$v.owner.phone.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.owner.phone.required">
                                        {{$t('validation.x_is_required',{x: $t('owners.contact_number')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="owner.email" identifier="email"
                                           :label="$t('owners.email')"
                                           :placeholder="$t('owners.email')" :disabled="!$store.getters.hasPermission('update owners') || is_saving">
                            </FormInputText>
                            <FormInputText v-model="owner.address" identifier="address"
                                           :label="$t('owners.address')"
                                           :placeholder="$t('owners.address')" :disabled="!$store.getters.hasPermission('update owners') || is_saving">
                            </FormInputText>
                            <FormInputSelect v-model="$v.owner.locality.$model" identifier="locality"
                                             :label="$t('owners.locality')"
                                             :display-custom-label="(row) => row.attributes.name"
                                             :placeholder="$t('owners.locality')" :options="localityOptions"
                                             track-by="id" :disabled="!$store.getters.hasPermission('update owners') || is_saving" :has-error="$v.owner.locality.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.owner.locality.required">
                                        {{$t('validation.x_is_required',{x: $t('owners.locality')})}}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputText v-model="owner.post_code" identifier="post_code"
                                           :label="$t('owners.post_code')"
                                           :placeholder="$t('owners.post_code')" :disabled="!$store.getters.hasPermission('update owners') || is_saving">
                            </FormInputText>
                        </FormGroupThree>
                    </div>
                </Form>
            </div>
            <div class="table-area">
                <vue-good-table
                        mode="remote"
                        styleClass="vgt-table striped vgt-custom"
                        :columns="columns"
                        :rows="properties"
                        :isLoading.sync="is_loading_properties"
                        :search-options="{
                enabled: false,
            }"
                        :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('owners.entries')}),
            }"
                        :totalRows="totalRecords"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-per-page-change="onPerPageChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'date'">

                        </div>
                        <div v-else-if="props.column.field === 'after'" class="td-after">
                            <router-link :to="{name: 'properties-update', params: {id: props.row.id}}">
                                <Button v-if="$store.getters.hasAnyPermission(['read properties', 'update properties'])"
                                        className="--secondary --outline --mini --big-text">
                                    <font-awesome-icon :icon="['fal', 'pencil']"/>
                                </Button>
                            </router-link>
                            <!--<Button className="&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text"
                                    :onclick="()=>togglePropertyDelete(props.row)">
                                <font-awesome-icon :icon="['fal', 'trash']"/>
                            </Button>-->
                        </div>
                        <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Headbar from "../../components/headbar/Headbar";
    import Form from "../../components/form/Form";
    import SectionHeader from "../../components/SectionHeader";
    import FormGroupThree from "../../components/form/FormGroupThree";
    import FormInputText from "../../components/form/FormInputText";
    import {required, email} from 'vuelidate/lib/validators'
    import FormInputSelect from "../../components/form/FormInputSelect";
    import Button from "../../components/Button";
    import ConfirmModal from "../../components/modal/ConfirmModal";

    export default {
        name: "owners-update-page",
        components: {Headbar, Form, SectionHeader, FormGroupThree, FormInputSelect, FormInputText, Button, ConfirmModal},
        data() {
            return {
                original: null,
                owner: {
                    name: null,
                    phone: null,
                    email: null,
                    address: null,
                    post_code: null,
                    locality: null
                },
                columns: [
                    {
                        label: this.$t('owners.status'),
                        field: row => row.attributes.status,
                        sortable: false,
                    },
                    {
                        label: this.$t('owners.property_id'),
                        field: row => row.attributes.reference,
                        sortable: false,
                    },
                    {
                        label: this.$t('owners.address'),
                        field: row => row.attributes.address,
                        sortable: false,
                    },
                    {
                        label: this.$t('owners.locality'),
                        field: row => row.attributes.locality,
                        sortable: false,
                    },
                    {
                        label: this.$t('owners.type'),
                        field: row => row.attributes.type,
                        sortable: false,
                    },
                    {
                        label: this.$t('owners.actions'),
                        field: 'after',
                        tdClass: 'td-after',
                        sortable: false
                    },
                ],

                properties: [],
                localityOptions: [],
                is_saving: false,
                is_loading_localities: false,
                is_loading_original: false,
                is_loading_properties: false,
                is_loading_owner_properties: false,
                is_deleting: false,
                totalRecords: null,
            }
        },
        validations: {
            owner: {
                name: {required},
                phone: {required},
                email: {},
                address: {},
                post_code: {},
                locality: {required}
            },
        },
        methods: {
            populate() {
                if (!this.original || !this.owner)
                    return;

                this.$v.owner.name.$model = this.original.attributes.name;
                this.$v.owner.phone.$model = this.original.attributes.phone;
                this.owner.email = this.original.attributes.email;
                this.owner.address = this.original.attributes.address;
                this.owner.post_code = this.original.attributes.post_code;
                this.$v.owner.locality.$model = _.find(this.localityOptions, {id: this.original.relationships.locality.data.id});
            },
            async retrieveOriginalOwner() {
                this.is_loading_original = false;
                await this.$axios.get(`owners/${this.$route.params.id}`)
                    .then(({data}) => {
                        this.original = data.data;
                        this.properties = this.original.relationships.properties.data;
                        this.is_loading_original = false;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('owners.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            async retrieveLocalityOptions() {
                this.is_loading_localities = false;
                await this.$axios.get('localities/list')
                    .then(({data}) => {
                        this.is_loading_localities = false;
                        this.localityOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_localities = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('localities.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
            },
            onPerPageChange(params) {
                this.updateParams({per_page: params.currentPerPage, page: 1});
            },
            onSortChange(params) {
                this.updateParams({sort: params});
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            save() {
                this.$v.owner.$touch();
                if (this.$v.owner.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.owner.$model);
                    payload.locality_id = this.$v.owner.locality.$model.id;
                    delete payload.locality;

                this.$axios.patch(`owners/${this.$route.params.id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('owners.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;

                    this.$router.push({name: 'owners-index'});
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('owners.error_update')),
                        type: 'error',
                    });
                });
            },
        },

        async mounted() {
            await this.retrieveOriginalOwner();
            await this.retrieveLocalityOptions();
            this.populate();
        },

        head() {
            return {
                title: {
                    inner: this.$t('owners.edit_owner')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        main {
            @apply px-0;

            .form-area {
                @apply flex flex-col flex-wrap px-9;

                @screen xl {
                    @apply flex-row;
                }

                .form-column {
                    @apply flex flex-col w-full;

                    @screen xl {
                        @apply mr-4;

                        width: calc(50% - 1rem);
                    }

                    .form {
                        @apply w-full mx-0;
                    }
                }

                .form {
                    @apply mb-8 w-full;

                    @screen xl {
                        @apply ml-4;

                        width: calc(50% - 1rem);
                    }

                    .form-body {
                        @apply px-8 py-7;
                    }

                    .row-add-container {
                        @apply px-8;
                    }
                }
            }

            .divider {
                @apply w-full border-b-2 border-primary;
            }

            .table-area {
                @apply px-9 py-7;
            }
        }

      .btn-save-mobile {
        @apply block;

        @screen md {
          @apply hidden;
        }
      }

      .btn-save-desktop {
        @apply hidden;

        @screen md {
          @apply block;
        }
      }
    }
</style>