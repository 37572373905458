<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasPermission('store users')"
                        className="--primary --small" :onclick="toggleCreate">
                    {{$t('users.add_user')}}
                </Button>
            </template>
            <template v-slot:right>
              <Search class="search-desktop" :placeholder="$t('users.search_users')" @search="search"/>
              <button class="btn-search-mobile btn-icon-only" @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                <font-awesome-icon v-else :icon="['far', 'search']"/>
              </button>
            </template>
            <template v-slot:expanded>
              <div class="headbar-expanded-container">
                <Search v-if="headbarExpanded === 'search'" :placeholder="$t('users.search_users')" @search="search"/>
              </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="users"
                    :isLoading.sync="is_loading_users"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('users.users')}),
            }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button v-if="$store.getters.hasPermission('update users')"
                                className="--secondary --outline --mini --big-text" :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('destroy users')"
                                className="--secondary --outline --mini --big-text" :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Search from "../../components/Search";
    import Button from "../../components/Button";
    import Select from "../../components/form/Select";
    import ConfirmModal from "../../components/modal/ConfirmModal";
    import UsersCreateModal from "../../components/users/UsersCreateModal";
    import UsersUpdateModal from "../../components/users/UsersUpdateModal";

    export default {
        name: "users-index-page",
        components: {ConfirmModal, Select, Button, Search, Headbar},
        data: function () {
            const columns = [
                {
                    label: this.$t('users.name'),
                    field: 'attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('users.email'),
                    field: 'attributes.email',
                    sortable: false,
                },
                {
                    label: this.$t('users.role'),
                    field: row => row.relationships.roles.data[0].attributes.name,
                    sortable: false,
                },
                // {
                //     label: this.$t('users.ip_address'),
                //     field: row => row.relationships.tokens.data[0].attributes.ip_address,
                //     sortable: false,
                // },
                // {
                //     label: this.$t('users.user_agent'),
                //     field: row => row.relationships.tokens.data[0].attributes.user_agent,
                //     sortable: false,
                // }
            ];

            if (this.$store.getters.hasAnyPermission(['update users', 'destroy users']))
                columns.push({
                    label: this.$t('users.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                });

            return {
                columns: columns,
                users: [],
                is_loading_users: false,
                totalRecords: null,
                serverParams: {},
                searchTerm: null,
                is_deleting: false,
                headbarExpanded: null
            }
        },
        methods: {
            toggleDelete(user) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('users.prompt_delete'),
                        subtext: this.$t('users.prompt_delete_subtext'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`users/${user.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.retrieveUsers();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('users.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            toggleCreate() {
                this.$modal.show(
                    UsersCreateModal, {},
                    {
                        name: 'users-create-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveUsers();
                        }
                    }
                );
            },
            toggleUpdate(user) {
                this.$modal.show(
                    UsersUpdateModal, {
                        user_id: user.id,
                    },
                    {
                        name: 'users-update-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveUsers();
                        }
                    }
                );
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveUsers();
            },
            onPerPageChange(params) {
                this.updateParams({per_page: params.currentPerPage, page: 1});
                this.retrieveUsers();
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.retrieveUsers();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm, retrieve = true, resetPage = true) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                if (resetPage) {
                    this.serverParams.page = 1;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: 1});
                    this.$router.push({query: {...this.$route.query, term: this.searchTerm, page: 1}});
                }

                if(retrieve)
                    this.retrieveUsers();
            },
            retrieveUsers() {
                this.is_loading_users = true;

                this.$axios.get('users', {params: this.serverParams})
                    .then(({data}) => {
                        this.users = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_users = false;
                    })
                    .catch(e => {
                        this.is_loading_users = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },
        mounted() {
            if (this.$route.query) {
                if (this.$route.query.page) {
                    this.serverParams.page = this.$route.query.page;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: +this.$route.query.page});
                }

                if (this.$route.query.term) {
                    this.searchTerm = this.$route.query.term;
                    this.search(this.$route.query.term, false, false);
                }
            }

            this.retrieveUsers();
        },
        watch:{
            $route (){
                this.search(this.$route.query.term, true, false);
            }
        },
        head() {
            return {
                title: {
                    inner: this.$t('nav.users')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        .headbar-expanded-container {
          @apply mt-4 flex flex-col gap-y-4 items-end;

          @screen md {
            @apply hidden;
          }
        }

        .btn-search-mobile, .btn-filter-mobile {
          @apply block;

          @screen md {
            @apply hidden;
          }

          &:active,&:focus{
            @apply outline-none;
          }
        }

        .search-desktop, .filter-desktop {
          @apply hidden;

          @screen md {
            @apply block;
          }
        }

        .filter-mobile {
          @apply max-w-xs;
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>
