<template>
    <div class="rent-upload-final-contract-modal">
        <ModalContainer :title="$t('properties.final_contract')" identifier="rent-upload-final-contract-modal"
                        :closable="true">
            <Form @submit="save" class="form" :disabled="is_saving">
                <p class="final_contract_text">{{$t('properties.final_contract_text')}}</p>

                <FormInputDateTime identifier="expires_at" :label="$t('properties.expires_at')"
                                   :only-date="true" formatted="Do MMM YYYY" v-model="$v.expires_at.$model"
                                   :placeholder="$t('properties.expires_at')"
                                   :disabled="is_saving" output-format="YYYY-MM-DD">
                </FormInputDateTime>

                <div class="upload-row">
                    <div class="upload-container">
                        <label v-if="attachment" for="rent-create-file-upload" class="has-attachment">
                            <p>{{attachment.name}}</p>
                            <button @click.prevent="attachment = null">
                                <font-awesome-icon :icon="['far','times']"/>
                            </button>
                        </label>
                        <label v-else for="rent-create-file-upload">
                            <font-awesome-icon :icon="['fal','upload']"/>
                            <p>{{$t('upload')}}</p>
                        </label>
                        <input type="file" name="file" id="rent-create-file-upload" accept="image/*,application/pdf"
                               @change="handleFileChange"
                               :disabled="is_saving"/>
                    </div>
                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{$t('save')}}
                    </Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import Button from "../Button";
    import {required} from 'vuelidate/lib/validators'
    import FormInputDateTime from "../form/FormInputDateTime";

    export default {
        name: "RentUploadFinalContractModal",
        components: {FormInputDateTime, Button, Form, ModalContainer},
        props: {
            rent_id: {
                required: true
            }
        },
        data() {
            return {
                attachment: null,
                expires_at: null,
                is_saving: false,
            }
        },
        validations: {
            attachment: {
                required
            },
            expires_at: {
                required
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('rent-upload-final-contract-modal', status);
            },
            save() {
                this.$v.$touch();
                if (this.$v.$anyError || this.is_saving)
                    return;

                let formData = new FormData();

                formData.append('expires_at', this.expires_at);
                formData.append('name', this.attachment.name);
                formData.append('document', this.attachment);

                this.$axios.post(`rents/${this.rent_id}/contracts`, formData).then(({data}) => {
                    this.$notify({
                        text: this.$t('properties.final_contract_saved'),
                        type: 'success',
                    });

                    this.close(true);

                    this.is_saving = false;
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('properties.error_final_rent_created')),
                        type: 'error',
                    });
                });
            },
            handleFileChange(e) {
                const files = e.target.files;

                if (files.length) {
                    this.attachment = files[0];
                } else {
                    this.attachment = null;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    p.final_contract_text {
        @apply text-sm text-black font-normal mb-5;
    }

    .upload-row {
        @apply w-full flex flex-row items-center justify-between mt-5;

        .upload-container {
            label {
                @apply py-3 px-5 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                svg {
                    @apply text-black text-lg;
                }

                p {
                    @apply text-black font-bold text-xs ml-3;
                }

                &.has-attachment {
                    @apply py-2;

                    p {
                        @apply ml-0 mr-3;
                    }

                    button {
                        @apply p-0 m-0 mt-1;
                    }
                }
            }

            input[type="file"] {
                @apply hidden;
            }
        }
    }
</style>