<template>
    <div class="owners-create-modal">
        <ModalContainer :title="$t('owners.add_owner')" identifier="owners-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.owner.name.$model" identifier="owner_name"
                                   :label="$t('owners.full_name')"
                                   :placeholder="$t('owners.full_name')" :disabled="is_saving"
                                   :has-error="$v.owner.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.owner.name.required">
                                {{$t('validation.x_is_required',{x: $t('owners.full_name')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.owner.phone.$model" identifier="contact_number"
                                   :label="$t('owners.contact_number')"
                                   :placeholder="$t('owners.contact_number')" :disabled="is_saving"
                                   :has-error="$v.owner.phone.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.owner.phone.required">
                                {{$t('validation.x_is_required',{x: $t('owners.contact_number')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="owner.email" identifier="email" :label="$t('owners.email')"
                                   :placeholder="$t('owners.email')" :disabled="is_saving">
                    </FormInputText>
                    <FormInputText v-model="owner.address" identifier="address" :label="$t('owners.address')"
                                   :placeholder="$t('owners.address')" :disabled="is_saving">
                    </FormInputText>
                    <FormInputSelect v-model="owner.locality" identifier="locality"
                                     :label="$t('owners.locality')" :options="localityOptions"
                                     :placeholder="$t('owners.locality')" :disabled="is_saving"
                                     :display-custom-label="(row) => row.attributes.name">
                    </FormInputSelect>
                    <FormInputText v-model="owner.post_code" identifier="post_code"
                                   :label="$t('owners.post_code')"
                                   :placeholder="$t('owners.post_code')" :disabled="is_saving">
                    </FormInputText>
                </FormGroupTwo>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputText from "../form/FormInputText";
    import FormInputSelect from "../form/FormInputSelect";
    import Button from "../Button";
    import {required, email} from 'vuelidate/lib/validators'

    export default {
        name: "OwnersCreateModal",
        components: {Button, FormInputSelect, FormInputText, FormGroupTwo, Form, ModalContainer},
        data() {
            return {
                owner: {
                    name: null,
                    phone: null,
                    email: null,
                    address: null,
                    post_code: null,
                    locality: null
                },
                is_saving: false,
                is_loading_localities: false,
                localityOptions: [],
            }
        },
        validations: {
            owner: {
                name: {required},
                phone: {required},
                email: {},
                address: {},
                post_code: {},
                locality: {}
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('owners-create-modal', status);
            },
            save() {
                this.$v.owner.$touch();
                if (this.$v.owner.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.owner.$model);
                if (this.owner.email === null || this.owner.email === "") {
                    delete payload.email;
                }

                if (this.owner.locality === null || this.owner.locality === "") {
                    delete payload.locality;
                }
                else {
                    payload.locality_id = this.owner.locality.id;
                    delete payload.locality;
                }

                if (this.owner.address === null || this.owner.address === "") {
                    delete payload.address;
                }

                if (this.owner.post_code === null || this.owner.post_code === "") {
                    delete payload.post_code;
                }

                this.$axios.post(`owners`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('owners.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('owners.error_create')),
                        type: 'error',
                    });
                });
            },
            retrieveLocalityOptions() {
                this.is_loading_localities = false;
                this.$axios.get('localities/list')
                    .then(({data}) => {
                        this.is_loading_localities = false;
                        this.localityOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_localities = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('localities.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        mounted() {
            this.retrieveLocalityOptions();
        }
    }
</script>

<style scoped>

</style>