<template>
    <nav class="menubar-container">
        <button class="btn-mobile-menu" @click="$store.commit('TOGGLE_MENU_EXPANDED')">
          <font-awesome-icon :icon="['fal', 'bars']"/>
        </button>

        <img src="../assets/logo.png" class="logo" >

        <div v-if="$store.getters.user" class="user-wrapper">
            <div class="user">
                <div class="avatar">
                    <avatar background-color="#A50202" :size="30" color="white"
                            :username="$store.getters.user.attributes.name"></avatar>
                </div>
                <p>{{$store.getters.user.attributes.name}}</p>
                <font-awesome-icon :icon="['far', 'angle-down']"/>
            </div>
            <nav>
                <ul class="menu">
                    <li>
                        <a @click="logout">{{$t('nav.logout')}}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </nav>
</template>

<script>
    import Avatar from 'vue-avatar'

    export default {
        name: "Menubar",
        components: {Avatar},
        methods: {
            async logout() {
                await this.$store.dispatch("logOut");
                this.$router.push({name: 'login'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menubar-container {
        @apply flex flex-row justify-between w-full bg-black h-14 text-white;

        grid-area: menubar;

        .btn-mobile-menu {
          @apply ml-8;

          @screen md {
            @apply hidden;
          }

          svg {
            @apply text-white text-xl;
          }

          &:active,&:focus{
            @apply outline-none;
          }
        }

        img.logo{
          @apply h-full w-auto py-2 ml-4;
        }

        .user-wrapper {
            @apply py-4 cursor-pointer mr-8;

            .user {
                @apply mx-4 flex flex-row items-center h-9 pb-4;

                .avatar {
                    @apply mr-3;
                }

                p {
                    @apply max-w-xs truncate text-sm hidden mr-3;

                    @screen md {
                      @apply block;
                    }
                }

                svg {
                    @apply text-2xl;
                }
            }

            nav {
                @apply relative hidden bg-black;
                z-index: 999;

                .menu {
                    @apply p-0 list-none;

                    li {
                        a {
                            @apply flex text-white cursor-pointer py-4 px-6 text-sm;
                        }
                    }
                }
            }

            &:hover, &:active, &:focus {
                nav {
                    @apply block;
                }
            }
        }
    }
</style>