<template>
    <div class="attributes-create-modal">
        <ModalContainer :title="$t('attributes.add_attribute')" identifier="attributes-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.attribute.name.$model" identifier="name" :label="$t('attributes.name')"
                                   :placeholder="$t('attributes.name')" :disabled="is_saving"
                                   :has-error="$v.attribute.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.attribute.name.required">
                                {{$t('validation.x_is_required',{x: $t('attributes.name')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.attribute.type.$model" identifier="type"
                                     :label="$t('attributes.type')" :options="typeOptions"
                                     :placeholder="$t('attributes.type')" :disabled="is_saving"
                                     :has-error="$v.attribute.type.$error">
                        <template v-if="!$v.attribute.type.required">
                            {{$t('validation.x_is_required',{x: $t('attributes.type')})}}
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>

                <div v-if="attribute.type == 'Dropdown'" class="row" v-for="(v, index) in $v.attribute.options.$each.$iter" >
                    <FormInputText v-model="v.option.$model" identifier="name" :class="{margin: index != 0}" :label="index == 0 ? $t('attributes.options') : ''" :disabled="is_saving" :has-error="v.option.$error">
                        <template v-slot:errors>
                            <p v-if="!v.option.required">
                                {{$t('validation.x_is_required',{x: $t('attributes.option')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <Button v-if="index == 0" className="plus-button --secondary --outline --small" :onclick="addOption">+</Button>
                </div>
            </Form>

            <div class="preview-container" v-if="attribute.type">
                <h2>{{$t('attributes.preview')}}</h2>
            </div>

            <FormGroupTwo :style="attribute.type === 'Checkbox' ? {'margin-left': '0rem'} : {'margin-left': '-1.25rem'}">
                <FormInputText v-if="attribute.type === 'Textbox'" identifier="textbox" :label="attribute.name" :placeholder="attribute.name"></FormInputText>
                <FormInputSelect v-else-if="attribute.type === 'Dropdown'" identifier="dropdown" v-model="dropdown_preview" :label="attribute.name" :placeholder="attribute.name" :options="attribute.options" displayLabel="option"></FormInputSelect>
                <p-check v-else-if="attribute.type === 'Checkbox'" v-model="checkbox_preview" class="p-icon p-curve" color="dark"><font-awesome-icon class="icon mdi mdi-check" slot="extra" :icon="['far', 'check']"/>{{attribute.name}}</p-check>
            </FormGroupTwo>

            <Button type="submit" className="--primary --small" :class="{spinner: is_saving}" :onclick="save">
                {{$t('save')}}
            </Button>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from '../modal/ModalContainer';
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormInputSelect from "../form/FormInputSelect";
    import Button from "../Button";
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import FormGroupTwo from "../form/FormGroupTwo";

    export default {
        name: "attributes-create-modal",
        components: {FormGroupTwo, Button, FormInputSelect, FormInputText, Form, ModalContainer},
        data() {
            return {
                attribute: {
                    name: null,
                    type: null,
                    options: [{option: null}],
                },
                dropdown_preview: null,
                checkbox_preview: null,
                is_saving: false,
                is_loading_categories: false,
                typeOptions: ['Textbox', 'Dropdown', 'Checkbox']
            }
        },
        validations: {
            attribute: {
                name: {required},
                type: {required},
                options: {
                    $each: {
                        option: {
                            required: requiredIf(function() {
                                return this.attribute.type === 'Dropdown'
                            })}
                    }
                }
            },
        },
        methods: {
            close(status) {
                this.$modal.hide('attributes-create-modal', status);
            },
            addOption() {
                this.attribute.options.push({option: null});
            },
            save() {
                this.$v.attribute.$touch();
                if (this.$v.attribute.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.attribute.$model);

                if(this.attribute.type === 'Dropdown')
                    payload.options = this.attribute.options.toString();
                else
                    delete payload.options;

                this.$axios.post(`attributes`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('attributes.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('attributes.error_create')),
                        type: 'error',
                    });
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

    .row {
        @apply flex flex-row;
    }

    .plus-button {
        @apply h-12 mt-8 ml-4;
        font-size: 18px !important;
    }

    .margin {
        margin-right: 4.5rem;
    }

    .preview-container {
        @apply flex flex-col;

        & > h2 {
            @apply font-bold text-lg py-3 border-b border-t border-grey-light mb-4;
        }

        .checkbox {
            @apply ml-4;
        }
    }

</style>