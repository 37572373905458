<template>
    <div class="mobile-menu-container">
        <router-link v-if="$store.getters.hasPermission('read properties')" :to="{name: 'properties-index'}"
                     class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'home']"/>
            </div>
            <p>{{ $t('nav.properties') }}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read owners')" :to="{name: 'owners-index'}"
                     class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-crown']"/>
            </div>
            <p>{{ $t('nav.owners') }}</p>
        </router-link>
        <!--    <router-link v-if="$store.getters.hasPermission('read tenants')" :to="{name: 'tenants-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">-->
        <!--        <div class="icon-container">-->
        <!--            <font-awesome-icon :icon="['fal', 'users-class']"/>-->
        <!--        </div>-->
        <!--        <p>{{$t('nav.tenants')}}</p>-->
        <!--    </router-link>-->
        <!--    <router-link :to="{name: 'attributes-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">-->
        <!--        <div class="icon-container">-->
        <!--            <font-awesome-icon :icon="['fal', 'sliders-h']"/>-->
        <!--        </div>-->
        <!--        <p>{{$t('nav.attributes')}}</p>-->
        <!--    </router-link>-->
        <router-link v-if="$store.getters.hasPermission('read users')" :to="{name: 'users-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{ $t('nav.users') }}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read roles')" :to="{name: 'roles-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{ $t('nav.roles') }}</p>
        </router-link>
        <router-link :to="{name: 'reports-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'file-chart-line']"/>
            </div>
            <p>{{ $t('nav.reports') }}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "MobileMenu"
}
</script>

<style lang="scss" scoped>
.mobile-menu-container {
    @apply flex-1 grid grid-cols-2 content-start gap-y-12 gap-x-8 h-auto p-8;

    @screen sm {
        @apply grid-cols-3;
    }

    @screen md {
        @apply hidden;
    }

    .nav-item {
        @apply flex flex-col items-center;

        .icon-container {
            @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
            height: 40px;
            width: 40px;

            svg {
                @apply text-black w-auto;

                height: 18px;
            }
        }

        p {
            @apply text-black text-sm font-bold mt-4 text-center;
        }

        &:hover, &:focus {
            .icon-container {
                @apply border-primary;
            }
        }

        &.router-link-active {
            .icon-container {
                @apply bg-primary border-primary;

                svg {
                    @apply text-white;
                }
            }
        }
    }
}
</style>