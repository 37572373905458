<template>
    <div class="property-modal">
        <ModalContainer :title="title" identifier="property-modal" :closable="true">
            <div>
                <div class="body">
                    <p>{{description}}</p>
                    <p>{{agency_fees_text}}</p>
                    <p>{{reference}}</p>
                </div>

                <div class="attributes">
                    <p v-html="attributes"></p>
                </div>

                <div class="body">
                    <p>{{date}}</p>
                </div>

                <div class="costs">
                    <p>{{deposit}}</p>
                    <p>{{price}}</p>
                </div>
            </div>
            <div class="buttons">
                <Button className="--primary --small" :onclick="copy">
                    {{$t('copy')}}
                </Button>

                <div class="download-container">
                    <button v-if="this.attachments.length" @click="download">
                        <font-awesome-icon :icon="['fal','download']"/>
                        <p>{{$t('properties.download')}}</p>
                    </button>
                </div>

            </div>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "./ModalContainer";
    import Button from "../Button";

    export default {
        name: "PropertyModal.vue",
        components: {Button, ModalContainer},
        data() {
            return {
                attachments: []
            }
        },
        props: {
            id: {
                type: Number,
                required: true,
            },
            title: {
                type: String,
                required: false,
                default: null,
            },
            description: {
                type: String,
                required: false,
                default: null,
            },
            agency_fees_text: {
                type: String,
                required: false,
                default: 'NO AGENCY FEES',
            },
            reference: {
                type: String,
                required: false,
                default: null,
            },
            attributes: {
                type: String,
                required: false,
                default: null,
            },
            date: {
                type: String,
                required: false,
                default: null,
            },
            deposit: {
                type: String,
                required: false,
                default: null,
            },
            price: {
                type: String,
                required: false,
                default: null,
            }
        },
        methods: {
            retrievePropertyDocuments() {
                this.$axios.get(`properties/${this.id}}/documents`)
                    .then(({data}) => {
                        if (data.data.length) {
                            this.attachments = data.data
                        }
                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_documents_retrieve')),
                            type: 'error',
                        });
                    });
            },
            copy() {
                let string = "";

                if (this.description)
                    string += this.description + "\n\n";

                if (this.agency_fees_text)
                    string += this.agency_fees_text + "\n\n";

                if (this.reference)
                    string += this.reference + "\n\n";

                if (this.attributes)
                    string += this.attributes.replace(/<br\s*[\/]?>/gi, '\n') + "\n";

                if (this.date)
                    string += this.date + "\n\n";

                if (this.deposit)
                    string += this.deposit + "\n";

                if (this.price)
                    string += this.price + "\n";

                this.$clipboard(string);

            },
            download() {
                this.$axios.get(`properties/${this.id}/export-data`, {responseType: 'blob',})
                    .then(({data}) => {
                        const blob = new Blob([data], {type: 'application/x-zip-compressed'});
                        const url = window.URL.createObjectURL(blob);

                        const tempLink = document.createElement('a');
                        tempLink.style.display = 'none';
                        tempLink.href = url;
                        tempLink.setAttribute('download', this.title + '.zip');
                        if (typeof tempLink.download === 'undefined') {
                            tempLink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(tempLink);
                        tempLink.click();
                        document.body.removeChild(tempLink);
                    }).catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('properties. error_download_images')),
                        type: 'error',
                    });
                });
            },
            close(status) {
                this.$modal.hide('property-modal', status);
            },
        },
        mounted() {
            this.retrievePropertyDocuments();
        }
    }
</script>

<style lang="scss" scoped>
    .body {
        @apply flex flex-col mt-4;

        p {
            @apply text-black text-sm mb-6;
        }
    }

    .attributes {
        @apply flex flex-col;

        p {
            @apply text-black text-sm;
        }
    }

    .costs {
        @apply flex flex-col;

        p {
            @apply text-black text-sm;
        }
    }

    .buttons {
        @apply w-full flex flex-row mt-5 items-center justify-between;

        .download-container {
            button {
                @apply py-3 px-5 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                svg {
                    @apply text-black text-lg;
                }

                p {
                    @apply text-black font-bold text-xs ml-3;
                }
            }
        }
    }
</style>


