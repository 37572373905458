<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{$t('profile.my_listed_properties')}}</h1>
            </template>
        </Headbar>

        <div class="divider"></div>
        <Headbar>
            <template v-slot:left>
                <h1>{{$t('profile.my_rented_properties')}}</h1>
            </template>
        </Headbar>

        <main>
            <rented-properties :rented_properties="rented_properties" :totalRecords="rented_properties_total" :is_loading="is_loading_rented_properties"></rented-properties>
        </main>
    </div>
</template>

<script>
    import Headbar from "../components/headbar/Headbar";
    import Button from "../components/Button";
    import RentedProperties from "../components/profile/RentedProperties";
    export default {
        name: "MyProfile",
        components: {RentedProperties, Button, Headbar},
        data() {
            return {
                profile: null,
                rented_properties: [],
                rented_properties_total: 0,
                is_loading_profile: false,
                is_loading_rented_properties: false
            }
        },
        methods: {
            retrieveRentedProperties() {
                if (!this.profile)
                    return;

                this.is_loading_rented_properties = false;
                this.$axios.post(`properties/my-rented`, {user_id: this.profile.id})
                    .then(({data}) => {
                        this.rented_properties = data.data;
                        this.rented_properties_total = data.meta.total;
                        this.is_loading_rented_properties = false;
                    })
                    .catch(e => {
                        this.is_loading_rented_properties = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('profile.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            async retrieveProfile() {
                this.is_loading_profile = false;
                await this.$axios.get(`profile`)
                    .then(({data}) => {
                        this.profile = data.data;
                        this.is_loading_profile = false;
                    })
                    .catch(e => {
                        this.is_loading_profile = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('profile.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        async mounted() {
            await this.retrieveProfile();

            this.retrieveRentedProperties();
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        main {
            .form-area {
                @apply flex flex-col flex-wrap px-9;

                @screen xl {
                    @apply flex-row;
                }

                .form-column {
                    @apply flex flex-col w-full;

                    @screen xl {
                        @apply mr-4;

                        width: calc(50% - 1rem);
                    }

                    .form {
                        @apply w-full mx-0;
                    }
                }

                .form {
                    @apply mb-8 w-full;

                    @screen xl {
                        @apply ml-4;

                        width: calc(50% - 1rem);
                    }

                    .form-body {
                        @apply px-8 py-7;
                    }

                    .row-add-container {
                        @apply px-8;
                    }
                }
            }

            .divider {
                @apply w-full border-b-2 border-primary;
            }

            .table-area {
                @apply px-9 py-7;
            }
        }

        .btn-save-mobile {
            @apply block;

            @screen md {
                @apply hidden;
            }
        }

        .btn-save-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }

    }
</style>