var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table striped vgt-custom","columns":_vm.columns,"rows":_vm.rented_properties,"isLoading":_vm.is_loading,"search-options":{
        enabled: false,
    },"pagination-options":{
        enabled: true,
        mode: 'records',
        setCurrentPage: 1,
        perPage: 15,
        perPageDropdown: [15],
        perPageDropdownEnabled: false,
        dropdownAllowAll: false,
        rowsPerPageLabel: this.$t('x_per_page', {x: this.$t('attributes.attributes')}),
    },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading=$event},"update:is-loading":function($event){_vm.is_loading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'date')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$moment.utc(props.row.attributes.from).local().format('DD/MM/YYYY')))])]):(props.column.field === 'status')?_c('div',{staticClass:"td-status"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'bottom',  arrow: true }),expression:"{ placement : 'bottom',  arrow: true }"}],class:("bulb " + (props.row.attributes.status)),attrs:{"content":_vm.$t(("properties." + (props.row.attributes.status)))}})]):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[_c('router-link',{attrs:{"to":{name: 'properties-update', params: {id: props.row.id}}}},[(_vm.$store.getters.hasAnyPermission(['read properties', 'update properties']))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1):_vm._e()],1),(_vm.$store.getters.hasPermission('destroy properties'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.toggleDelete(props.row); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e(),_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.toggleExport(props.row); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'file-export']}})],1)],1):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }