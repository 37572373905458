<template>
    <div class="rent-details-modal">
        <ModalContainer :title="$t('properties.rent_x_details', {x: rent.id})" identifier="rent-details-modal"
                        :closable="true">
            <div class="row-container">
                <div v-for="contract in rent.relationships.contracts.data" class="row">
                    <div class="col-type">
                        <p class="title">{{$t('properties.type')}}</p>
                        <p class="value">{{$t(`properties.contract_type_${contract.attributes.type}`)}}</p>
                    </div>
                    <div class="col-expires">
                        <p class="title">{{$t('properties.expires_at')}}</p>
                        <p class="value">
                            {{$moment.utc(contract.attributes.expires_at).local().format('DD/MM/YYYY')}}
                        </p>
                    </div>
                    <div class="col-actions">
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>download(contract.relationships.documents.data[0].id)">
                            <font-awesome-icon :icon="['fal', 'download']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleEdit(contract)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputDateTime from "../form/FormInputDateTime";
    import Button from "../Button";
    import FormInputSelect from "../form/FormInputSelect";
    import {required} from 'vuelidate/lib/validators'
    import RentEditContractModal from "./RentEditContractModal";

    export default {
        name: "RentDetailsModal",
        components: {FormInputSelect, Button, FormInputDateTime, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            rent: {
                required: true,
            }
        },

        methods: {
            close(status) {
                this.$modal.hide('rent-details-modal', status);
            },
            download(document_id) {
                this.$axios.get(`documents/${document_id}/download`, {
                    responseType: 'blob',
                }).then(({data}) => {
                    const blob = new Blob([data], {type: 'image/jpeg'});
                    const url = window.URL.createObjectURL(blob);

                    const tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = url;
                    tempLink.setAttribute('download', document_id + '.jpg');
                    if (typeof tempLink.download === 'undefined') {
                        tempLink.setAttribute('target', '_blank');
                    }

                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                }).catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('properties.error_download_document')),
                        type: 'error',
                    });
                });
            },
            toggleEdit(contract) {
                this.close(false);
                this.$modal.show(
                    RentEditContractModal, {
                        contract: contract,
                    },
                    {
                        name: 'rent-edit-contract-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            // if (e.params === true)
                            //     this.retrieveOriginalProperty();
                        }
                    }
                );
            }
        },
    }
</script>

<style lang="scss" scoped>
    .rent-details-modal {
        .row-container {
            @apply flex flex-col;

            .row {
                @apply flex flex-row items-start mb-4;

                .col-type {
                    @apply w-1/4 flex flex-col;
                }

                .col-confirmed {
                    @apply w-1/4 flex flex-col;
                }

                .col-expires {
                    @apply w-1/4 flex flex-col;
                }

                .col-actions {
                    @apply w-1/4 flex flex-row;

                    & > * {
                        @apply mx-1;
                    }
                }

                p.title {
                    @apply text-xs font-bold text-black mb-1;
                }

                p.value {
                    @apply text-xs font-bold text-grey-dark;
                }
            }
        }
    }
</style>