<template>
    <vue-good-table
        mode="remote"
        styleClass="vgt-table striped vgt-custom"
        :columns="columns"
        :rows="rented_properties"
        :isLoading.sync="is_loading"
        :search-options="{
            enabled: false,
        }"
        :pagination-options="{
            enabled: true,
            mode: 'records',
            setCurrentPage: 1,
            perPage: 15,
            perPageDropdown: [15],
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
            rowsPerPageLabel: this.$t('x_per_page', {x: this.$t('attributes.attributes')}),
        }"
        :totalRows="totalRecords"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange">
        <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'date'">
                <p>{{$moment.utc(props.row.attributes.from).local().format('DD/MM/YYYY')}}</p>
            </div>
            <div v-else-if="props.column.field === 'status'" class="td-status">
                <div :class="`bulb ${props.row.attributes.status}`"
                     :content="$t(`properties.${props.row.attributes.status}`)"
                     v-tippy="{ placement : 'bottom',  arrow: true }"></div>
            </div>
            <div v-else-if="props.column.field === 'after'" class="td-after">
                <router-link :to="{name: 'properties-update', params: {id: props.row.id}}">
                    <Button v-if="$store.getters.hasAnyPermission(['read properties', 'update properties'])"
                            className="--secondary --outline --mini --big-text">
                        <font-awesome-icon :icon="['fal', 'pencil']"/>
                    </Button>
                </router-link>
                <Button v-if="$store.getters.hasPermission('destroy properties')"
                        className="--secondary --outline --mini --big-text"
                        :onclick="()=>toggleDelete(props.row)">
                    <font-awesome-icon :icon="['fal', 'trash']"/>
                </Button>
                <Button className="--secondary --outline --mini --big-text"
                        :onclick="()=>toggleExport(props.row)">
                    <font-awesome-icon :icon="['fal', 'file-export']"/>
                </Button>
            </div>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
    </vue-good-table>
</template>

<script>
    import Button from "../../components/Button";
    import PropertyModal from "../modal/PropertyModal";
    import ConfirmModal from "../modal/ConfirmModal";

    export default {
        name: "RentedProperties",
        components: {Button},
        props: {
            rented_properties: {
                type: Array,
                required: true
            },
            is_loading: {
                type: Boolean,
                required: true
            },
            totalRecords: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                columns: [
                    {
                        label: this.$t('properties.status'),
                        field: 'status',
                        sortable: false,
                    },
                    {
                        label: this.$t('properties.reference'),
                        field: row => `${row.attributes.reference}`,
                        sortable: false,
                    },
                    {
                        label: this.$t('properties.location'),
                        field: row => row.relationships.locality.data.attributes.name,
                        sortable: false,
                    },
                    {
                        label: this.$t('properties.no_of_bedrooms'),
                        field: row => row.attributes.amenities.bedrooms,
                        sortable: false,
                    },
                    {
                        label: this.$t('properties.price'),
                        field: row => `€${row.attributes.monthly_rent}`,
                        sortable: false,
                    },
                    {
                        label: this.$t('properties.agent'),
                        field: row => row.relationships.user.data.attributes.name,
                        sortable: false,
                    }
                ],
                is_deleting: false,
            }
        },
        methods: {
            toggleDelete(property) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('properties.prompt_delete'),
                        subtext: this.$t('properties.prompt_delete_subtext'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`properties/${property.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.$parent.retrieveProperties();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('properties.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            toggleExport(property) {
                this.$axios.get(`properties/${property.id}`)
                    .then(({data}) => {
                        this.property = data.data;
                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });

                let attributes = "";

                if (property.attributes.amenities.air_conditioner)
                    attributes += "-air conditioning<br/>";

                if (property.attributes.amenities.kitchen_living_dining)
                    attributes += "-kitchen/living/dining<br/>";

                if (property.attributes.amenities.bedrooms)
                    attributes += "-" + property.attributes.amenities.bedrooms + " bedrooms<br/>";

                if (property.attributes.amenities.bathrooms)
                    attributes += "-" + property.attributes.amenities.bathrooms + " bathrooms<br/>";

                if (property.attributes.amenities.duplex)
                    attributes += "-duplex<br/>";

                if (property.attributes.amenities.dish_washer)
                    attributes += "-dish washer<br/>";

                if (property.attributes.amenities.utility_room)
                    attributes += "-utility room<br/>";

                if (property.attributes.amenities.seafront)
                    attributes += "-sea front<br/>";

                if (property.attributes.amenities.seaview)
                    attributes += "-sea view<br/>";

                if (property.attributes.amenities.balcony)
                    attributes += "-balconies<br/>";

                if (property.attributes.amenities.intercom)
                    attributes += "-intercom<br/>";

                if (property.attributes.amenities.open_plan)
                    attributes += "-open plan<br/>";

                if (property.attributes.amenities.television)
                    attributes += "-television<br/>";

                if (property.attributes.amenities.ceiling_fans)
                    attributes += "-ceiling fans<br/>";

                if (property.attributes.amenities.study_room)
                    attributes += "-study room<br/>";

                if (property.attributes.amenities.desk)
                    attributes += "-desk<br/>";

                if (property.attributes.amenities.roof)
                    attributes += "-roof<br/>";

                if (property.attributes.amenities.washing_machine)
                    attributes += "-washing machine<br/>";

                if (property.attributes.amenities.tumble_dryer)
                    attributes += "-tumble dryer<br/>";

                if (property.attributes.amenities.lift)
                    attributes += "-lift<br/>";

                if (property.attributes.amenities.pool)
                    attributes += "-pool<br/>";

                if (property.attributes.amenities.jacuzzi)
                    attributes += "-jacuzzi<br/>";

                if (property.attributes.amenities.garden_yard)
                    attributes += "-garden/yard<br/>";

                if (property.attributes.amenities.wine_cooler)
                    attributes += "-wine cooler<br/>";

                if (property.attributes.amenities.internet)
                    attributes += "-internet included<br/>";

                if (property.attributes.amenities.pet_friendly)
                    attributes += "-pet friendly<br/>";

                this.$modal.show(
                    PropertyModal, {
                        id: property.id,
                        title: property.attributes.reference,
                        description: property.attributes.amenities.bedrooms + ' bedroom ' + property.attributes.type + ' in ' + property.relationships.locality.data.attributes.name,
                        agency_fees_text: 'NO AGENCY FEES',
                        reference: 'ID: ' + property.attributes.reference,
                        attributes: attributes,
                        date: 'Available from: ' + this.$moment.utc(property.attributes.available_at).local().format('Do MMM YYYY'),
                        deposit: 'Deposit: €' + property.attributes.deposit,
                        price: 'Price: €' + property.attributes.monthly_rent,
                    },
                    {
                        name: 'property-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    },
                )
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.$parent.retrieveProperties();

                this.$router.push({query: {...this.$route.query, page: params.currentPage}});
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.$parent.retrieveProperties();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
        },
        mounted() {
            if (this.$store.getters.hasAnyPermission(['read properties', 'update properties', 'destroy properties']))
                this.columns.push({
                    label: this.$t('properties.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                });
        }
    }
</script>

<style lang="scss" scoped>
    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .td-status {
        .bulb {
            @apply w-4 h-4 bg-black rounded-full ml-5;

            &.available {
                @apply bg-available;
            }

            &.unavailable {
                @apply bg-unavailable;
            }

            &.pending {
                @apply bg-pending;
            }

            &.upcoming {
                @apply bg-upcoming;
            }
        }
    }
</style>