import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import PropertiesIndex from "../views/properties/Index";
import PropertiesCreate from "../views/properties/Create";
import PropertiesUpdate from "../views/properties/Update";
import OwnersIndex from "../views/owners/Index";
import OwnersUpdate from "../views/owners/Update";
import UsersIndex from "../views/users/Index";
import RolesIndex from "../views/roles/Index";
import AttributesIndex from "../views/attributes/Index";
import ReportsIndex from "../views/reports/Index";
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import {i18n} from '../locale/i18n';
import TenantsIndex from "../views/tenants/Index";
import MyProfile from "../views/MyProfile";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/properties'
    },
    {
        path: '/properties',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'properties-index',
                component: PropertiesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read properties'],
                },
            },
            {
                path: 'add',
                name: 'properties-create',
                component: PropertiesCreate,
                meta: {
                    auth: true,
                    all_permissions: ['store properties'],
                },
            },
            {
                path: ':id',
                name: 'properties-update',
                component: PropertiesUpdate,
                meta: {
                    auth: true,
                    any_permissions: ['read properties', 'update properties'],
                },
            },
        ]
    },
    {
        path: '/owners',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'owners-index',
                component: OwnersIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read owners'],
                },
            },
            {
                path: ':id',
                name: 'owners-update',
                component: OwnersUpdate,
                meta: {
                    auth: true,
                    any_permissions: ['read owners', 'update owners'],
                },
            },
        ]
    },
    {
        path: '/tenants',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/tenants',
                name: 'tenants-index',
                component: TenantsIndex,
            },
        ]
    },
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'users-index',
                component: UsersIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read users'],
                },
            },
        ]
    },
    {
        path: '/roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'roles-index',
                component: RolesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/attributes',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'attributes-index',
                component: AttributesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read attributes'],
                },
            },
        ]
    },
    {
        path: '/reports',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'reports-index',
                component: ReportsIndex,
                meta: {
                    auth: true,
                },
            },
        ]
    },
    {
        path: '/profile',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'profile',
                component: MyProfile,
                meta: {
                    auth: true,
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            } else if (store.getters.token && store.getters.token.expires_at < new Date().toISOString()) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                await store.dispatch("logOut");
                return next({name: 'login'});
            }

        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    return next();
});

export default router;
