<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasPermission('store owners')"
                        className="--primary --small" :onclick="toggleCreate">
                    {{$t('owners.add_owner')}}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('owners.search_owners')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only" @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                  <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                  <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
              <div class="headbar-expanded-container">
                <Search v-if="headbarExpanded === 'search'" :placeholder="$t('owners.search_owners')" @search="search"/>
              </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="owners"
                    :isLoading.sync="is_loading_owners"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage:15,
                perPageDropdown: [15],
                perPageDropdownEnabled: false,
                dropdownAllowAll: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('owners.owners')}),
            }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'owners-update', params: {id: props.row.id}}">
                            <Button v-if="$store.getters.hasAnyPermission(['read owners', 'update owners'])"
                                    className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>
                        <Button v-if="$store.getters.hasPermission('destroy owners')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Search from "../../components/Search";
    import Button from "../../components/Button";
    import OwnersCreateModal from "../../components/owners/OwnersCreateModal";
    import ConfirmModal from "../../components/modal/ConfirmModal";

    export default {
        name: "owners-index-page",
        components: {Button, Headbar, Search},
        data: function() {
            const columns = [
                {
                    label: this.$t('owners.full_name'),
                    field: 'attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('owners.contact_number'),
                    field: 'attributes.phone',
                    sortable: false,
                },
                {
                    label: this.$t('owners.email'),
                    field: 'attributes.email',
                    sortable: false,
                }
            ];

            if (this.$store.getters.hasAnyPermission(['read owners', 'update owners', 'destroy owners']))
                columns.push({
                    label: this.$t('owners.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                })

            return {
                columns: columns,
                owners: [],
                is_loading_owners: false,
                totalRecords: null,
                serverParams: {},
                searchTerm: null,
                is_deleting: false,
                headbarExpanded: null
            }
        },
        methods: {
            toggleCreate() {
                this.$modal.show(
                    OwnersCreateModal, {},
                    {
                        name: 'owners-create-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        minHeight: '500px',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveOwners();
                        }
                    }
                );
            },
            toggleDelete(owner) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('owners.prompt_delete'),
                        subtext: this.$t('owners.prompt_delete_subtext'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`owners/${owner.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.retrieveOwners();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('users.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveOwners();
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.retrieveOwners();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm, retrieve = true, resetPage = true) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                if (resetPage) {
                    this.serverParams.page = 1;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: 1});
                    this.$router.push({query: {...this.$route.query, term: this.searchTerm, page: 1}});
                }

                if(retrieve)
                    this.retrieveOwners();
            },
            retrieveOwners() {
                this.is_loading_owners = true;

                this.$axios.get('owners', {params: this.serverParams})
                    .then(({data}) => {
                        this.owners = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_owners = false;
                    })
                    .catch(e => {
                        this.is_loading_owners = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('owners.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        mounted() {
            if (this.$route.query) {
                if (this.$route.query.page) {
                    this.serverParams.page = this.$route.query.page;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: +this.$route.query.page});
                }

                if (this.$route.query.term) {
                    this.searchTerm = this.$route.query.term;
                    this.search(this.$route.query.term, false, false);
                }
            }
            this.retrieveOwners();
        },
        head() {
            return {
                title: {
                    inner: this.$t('nav.owners')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        .headbar-expanded-container {
          @apply mt-4 flex flex-col gap-y-4 items-end;

          @screen md {
            @apply hidden;
          }
        }

        .btn-search-mobile, .btn-filter-mobile {
          @apply block;

          @screen md {
            @apply hidden;
          }

          &:active,&:focus{
            @apply outline-none;
          }
        }

        .search-desktop, .filter-desktop {
          @apply hidden;

          @screen md {
            @apply block;
          }
        }

        .filter-mobile {
          @apply max-w-xs;
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>