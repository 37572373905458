<template>
    <div class="tenants-create-modal">
        <ModalContainer :title="$t('tenants.add_tenant')" identifier="tenants-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.tenant.name.$model" identifier="name" :label="$t('tenants.name')"
                                   :placeholder="$t('tenants.name')" :disabled="is_saving"
                                   :has-error="$v.tenant.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.tenant.name.required">
                                {{$t('validation.x_is_required',{x: $t('tenants.name')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.tenant.email.$model" identifier="email" :label="$t('tenants.email')"
                                   :placeholder="$t('tenants.email')" :disabled="is_saving"
                                   :has-error="$v.tenant.email.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.tenant.email.required">
                                {{$t('validation.x_is_required',{x: $t('tenants.email')})}}
                            </p>
                            <p v-else-if="!$v.tenant.email.email">
                                {{$t('auth.enter_valid_email')}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.tenant.phone.$model" identifier="phone" :label="$t('tenants.phone')"
                                   :placeholder="$t('tenants.phone')" :disabled="is_saving"
                                   :has-error="$v.tenant.phone.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.tenant.phone.required">
                                {{$t('validation.x_is_required',{x: $t('tenants.phone')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.tenant.property_type.$model" identifier="property_type"
                                     :label="$t('tenants.property_type')" track-by="column"
                                     :options="typeOptions" :placeholder="$t('tenants.property_type')"
                                     :disabled="is_saving" display-label="name"
                                     :has-error="$v.tenant.property_type.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.tenant.property_type.required">
                                {{$t('validation.x_is_required',{x: $t('tenants.property_type')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputText v-model="$v.tenant.budget.$model" identifier="budget" :label="$t('tenants.budget')"
                                   :placeholder="$t('tenants.budget')" :disabled="is_saving"
                                   :has-error="$v.tenant.budget.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.tenant.budget.required">
                                {{$t('validation.x_is_required',{x: $t('tenants.budget')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.tenant.nationality.$model" identifier="nationality"
                                   :label="$t('tenants.nationality')"
                                   :placeholder="$t('tenants.nationality')" :disabled="is_saving"
                                   :has-error="$v.tenant.nationality.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.tenant.nationality.required">
                                {{$t('validation.x_is_required',{x: $t('tenants.nationality')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.tenant.occupation.$model" identifier="occupation"
                                   :label="$t('tenants.occupation')"
                                   :placeholder="$t('tenants.occupation')" :disabled="is_saving"
                                   :has-error="$v.tenant.occupation.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.tenant.occupation.required">
                                {{$t('validation.x_is_required',{x: $t('tenants.occupation')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";

    export default {
        name: "TenantsCreateModal",
        components: {Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                tenant: {
                    name: null,
                    email: null,
                    phone: null,
                    property_type: null,
                    budget: null,
                    nationality: null,
                    occupation: null,
                },
                is_saving: false,
                is_loading_roles: false,
                is_loading_type_options: false,
                roleOptions: [],
                typeOptions: []
            }
        },
        validations: {
            tenant: {
                name: {required},
                email: {required, email},
                phone: {required},
                property_type: {required},
                budget: {required},
                nationality: {required},
                occupation: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('tenants-create-modal', status);
            },
            save() {
                this.$v.tenant.$touch();
                if (this.$v.tenant.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.tenant.$model);
                payload.property_type = this.$v.tenant.property_type.$model.column;

                this.$axios.post(`tenants`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('tenants.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('tenants.error_create')),
                        type: 'error',
                    });
                });
            },
            retrieveTypeOptions() {
                this.is_loading_type_options = false;
                this.$axios.get('properties/list-types')
                    .then(({data}) => {
                        this.is_loading_type_options = false;
                        this.typeOptions = data;
                    })
                    .catch(e => {
                        this.is_loading_type_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },

        mounted() {
            this.retrieveTypeOptions();
        }
    }
</script>
