<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <router-link :to="{name: 'properties-create'}">
                    <Button v-if="$store.getters.hasPermission('store properties')" className="--primary --small">
                        {{$t('properties.add_property')}}
                    </Button>
                </router-link>
            </template>
            <template v-slot:right>
                <Select v-show="!filter_verification || filter_verification.value !== 'unverified'"
                        class="filter-desktop" v-model="filter_status" :options="filter_status_options"
                        :allowEmpty="false"
                        className="filter" @updated="filterUpdated(true, true)" label="label" track-by="value"/>
                <Select class="filter-desktop" v-model="filter_verification" :options="filter_verification_options"
                        :allowEmpty="false"
                        className="filter" @updated="filterUpdated(true, true)" label="label" track-by="value"/>
                <button class="btn-filter-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'filter' ? null : 'filter'">
                    <font-awesome-icon v-if="headbarExpanded === 'filter'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'filter']"/>
                </button>
                <Search class="search-desktop" :placeholder="$t('properties.search_properties')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('properties.search_properties')"
                            @search="search"/>
                    <Select v-show="!filter_verification || filter_verification.value !== 'unverified'"
                            class="filter-mobile" v-if="headbarExpanded === 'filter'" v-model="filter_status"
                            :options="filter_status_options" :allowEmpty="false"
                            className="filter" @updated="filterUpdated(true, true)" label="label" track-by="value"/>
                    <Select class="filter-mobile" v-if="headbarExpanded === 'filter'" v-model="filter_verification"
                            :options="filter_verification_options" :allowEmpty="false"
                            className="filter" @updated="filterUpdated(true, true)" label="label" track-by="value"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="properties"
                    :isLoading.sync="is_loading_properties"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="paginationOptions"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'date'">
                        <p>{{$moment.utc(props.row.attributes.from).local().format('DD/MM/YYYY')}}</p>
                    </div>
                    <div v-else-if="props.column.field === 'status'" class="td-status">
                        <div :class="`bulb ${props.row.attributes.status}`"
                             :content="$t(`properties.${props.row.attributes.status}`)"
                             v-tippy="{ placement : 'bottom',  arrow: true }"></div>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'properties-update', params: {id: props.row.id}}">
                            <Button v-if="$store.getters.hasAnyPermission(['read properties', 'update properties'])"
                                    className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>
                        <Button v-if="$store.getters.hasPermission('destroy properties')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleExport(props.row)">
                            <font-awesome-icon :icon="['fal', 'file-export']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Search from "../../components/Search";
    import Button from "../../components/Button";
    import Select from "../../components/form/Select";
    import ConfirmModal from "../../components/modal/ConfirmModal";
    import PropertyModal from "../../components/modal/PropertyModal";

    export default {
        name: "properties-index-page",
        components: {ConfirmModal, Select, Button, Search, Headbar},
        data: function () {
            const columns = [
                {
                    label: this.$t('properties.status'),
                    field: 'status',
                    sortable: false,
                },
                {
                    label: this.$t('properties.reference'),
                    field: row => `${row.attributes.reference}`,
                    sortable: false,
                },
                {
                    label: this.$t('properties.location'),
                    field: row => row.relationships.locality.data.attributes.name,
                    sortable: false,
                },
                {
                    label: this.$t('properties.no_of_bedrooms'),
                    field: row => row.attributes.amenities.bedrooms,
                    sortable: false,
                },
                {
                    label: this.$t('properties.price'),
                    field: row => `€${row.attributes.monthly_rent}`,
                    sortable: false,
                },
                {
                    label: this.$t('properties.agent'),
                    field: row => row.relationships.user.data.attributes.name,
                    sortable: false,
                }
            ];

            if (this.$store.getters.hasAnyPermission(['read properties', 'update properties', 'destroy properties']))
                columns.push({
                    label: this.$t('properties.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                });

            const filter_status_options = [
                {
                    label: this.$t('all'),
                    value: 'all',
                },
                {
                    label: this.$t('properties.available'),
                    value: 'available',
                },
                {
                    label: this.$t('properties.upcoming'),
                    value: 'upcoming',
                },
                {
                    label: this.$t('properties.unavailable'),
                    value: 'unavailable',
                },
                /*{
                  label:this.$t('properties.pending'),
                  value:'pending',
                }*/
            ]

            const filter_verification_options = [
                {
                    label: this.$t('all'),
                    value: 'all',
                },
                {
                    label: this.$t('properties.verified_only'),
                    value: 'verified',
                },
                {
                    label: this.$t('properties.unverified_only'),
                    value: 'unverified',
                }
            ];

            return {
                columns: columns,
                properties: [],
                filter_verification: {
                    label: this.$t('properties.verified_only'),
                    value: 'verified',
                },
                filter_status: {
                    label: this.$t('properties.available'),
                    value: 'available',
                },
                filter_verification_options: filter_verification_options,
                filter_status_options: filter_status_options,
                serverParams: {
                    filters: [
                        {
                            filter_by: 'status',
                            filter_operator: '=',
                            filter_value: 'available'
                        },
                        {
                            filter_by: 'verified_at',
                            filter_operator: '!=',
                            filter_value: null
                        }
                    ],
                    sort_by: 'created_at',
                    sort_order: 'desc'
                },
                is_loading_properties: false,
                totalRecords: null,
                searchTerm: null,
                is_deleting: false,
                property: [],
                availableDates: [],
                paginationOptions: {
                    enabled: true,
                    mode: 'records',
                    setCurrentPage: 1,
                    perPage: 15,
                    perPageDropdown: [15],
                    perPageDropdownEnabled: false,
                    dropdownAllowAll: false,
                    rowsPerPageLabel: this.$t('x_per_page', {x: this.$t('properties.properties')}),
                },
                headbarExpanded: null
            }
        },
        methods: {
            toggleDelete(property) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('properties.prompt_delete'),
                        subtext: this.$t('properties.prompt_delete_subtext'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`properties/${property.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.retrieveProperties();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('properties.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            toggleExport(property) {
                this.$axios.get(`properties/${property.id}`)
                    .then(({data}) => {
                        this.property = data.data;
                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });

                let attributes = "";

                if (property.attributes.amenities.floor)
                    if(property.attributes.amenities.floor == 1)
                        attributes += "" + property.attributes.amenities.floor + " floor<br/>";
                    else
                        attributes += "" + property.attributes.amenities.floor + " floors<br/>";

                if (property.attributes.amenities.bedrooms)
                    if(property.attributes.amenities.bedrooms == 1)
                        attributes += "" + property.attributes.amenities.bedrooms + " bedroom<br/>";
                    else
                        attributes += "" + property.attributes.amenities.bedrooms + " bedrooms<br/>";

                if (property.attributes.amenities.bathrooms)
                    if(property.attributes.amenities.bathrooms == 1)
                        attributes += "" + property.attributes.amenities.bathrooms + " bathroom<br/>";
                    else
                        attributes += "" + property.attributes.amenities.bathrooms + " bathrooms<br/>";

                if (property.attributes.amenities.sleeps)
                    if(property.attributes.amenities.sleeps == 1)
                        attributes += "" + property.attributes.amenities.sleeps + " sleep<br/>";
                    else
                        attributes += "" + property.attributes.amenities.sleeps + " sleeps<br/>";

                if (property.attributes.amenities.air_conditioner)
                    if(property.attributes.amenities.air_conditioner == 1)
                        attributes += "" + property.attributes.amenities.air_conditioner + " air conditioner<br/>";
                    else
                        attributes += "" + property.attributes.amenities.air_conditioner + " air conditioners<br/>";

                if(property.attributes.amenities.floor || property.attributes.amenities.bedrooms || property.attributes.amenities.bathrooms || property.attributes.amenities.sleeps || property.attributes.amenities.air_conditioner)
                    attributes += "<br/>";

                if (property.attributes.amenities.air_conditioner)
                    attributes += "-air conditioning<br/>";

                if (property.attributes.amenities.kitchen_living_dining)
                    attributes += "-kitchen/living/dining<br/>";


                if (property.attributes.amenities.duplex)
                    attributes += "-duplex<br/>";

                if (property.attributes.amenities.dish_washer)
                    attributes += "-dish washer<br/>";

                if (property.attributes.amenities.utility_room)
                    attributes += "-utility room<br/>";

                if (property.attributes.amenities.seafront)
                    attributes += "-sea front<br/>";

                if (property.attributes.amenities.seaview)
                    attributes += "-sea view<br/>";

                if (property.attributes.amenities.balcony)
                    attributes += "-balconies<br/>";

                if (property.attributes.amenities.intercom)
                    attributes += "-intercom<br/>";

                if (property.attributes.amenities.open_plan)
                    attributes += "-open plan<br/>";

                if (property.attributes.amenities.television)
                    attributes += "-television<br/>";

                if (property.attributes.amenities.ceiling_fans)
                    attributes += "-ceiling fans<br/>";

                if (property.attributes.amenities.study_room)
                    attributes += "-study room<br/>";

                if (property.attributes.amenities.desk)
                    attributes += "-desk<br/>";

                if (property.attributes.amenities.roof)
                    attributes += "-roof<br/>";

                if (property.attributes.amenities.washing_machine)
                    attributes += "-washing machine<br/>";

                if (property.attributes.amenities.tumble_dryer)
                    attributes += "-tumble dryer<br/>";

                if (property.attributes.amenities.lift)
                    attributes += "-lift<br/>";

                if (property.attributes.amenities.pool)
                    attributes += "-pool<br/>";

                if (property.attributes.amenities.jacuzzi)
                    attributes += "-jacuzzi<br/>";

                if (property.attributes.amenities.garden_yard)
                    attributes += "-garden/yard<br/>";

                if (property.attributes.amenities.wine_cooler)
                    attributes += "-wine cooler<br/>";

                if (property.attributes.amenities.internet)
                    attributes += "-internet included<br/>";

                if (property.attributes.amenities.pet_friendly)
                    attributes += "-pet friendly<br/>";

                this.$modal.show(
                    PropertyModal, {
                        id: property.id,
                        title: property.attributes.reference,
                        description: property.attributes.amenities.bedrooms + ' bedroom ' + property.attributes.type + ' in ' + property.relationships.locality.data.attributes.name,
                        agency_fees_text: 'NO AGENCY FEES',
                        reference: 'ID: ' + property.attributes.reference,
                        attributes: attributes,
                        date: 'Available from: ' + this.$moment.utc(property.attributes.available_at).local().format('Do MMM YYYY'),
                        deposit: 'Deposit: €' + property.attributes.deposit,
                        price: 'Price: €' + property.attributes.monthly_rent,
                    },
                    {
                        name: 'property-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal height-fix',
                    },
                )
            },
            filterUpdated(retrieve = false, resetPage = true) {
                const filters = [];

                if (this.filter_verification) {
                    if (this.filter_verification.value === 'verified') {
                        filters.push({
                            filter_by: 'verified_at',
                            filter_operator: '!=',
                            filter_value: null
                        })
                    } else if (this.filter_verification.value === 'unverified') {
                        filters.push({
                            filter_by: 'verified_at',
                            filter_operator: '=',
                            filter_value: null
                        })
                    }
                }

                if (!this.filter_verification || this.filter_verification.value !== 'unverified') {
                    if (this.filter_status.value !== 'all')
                        filters.push({
                            filter_by: 'status',
                            filter_operator: '=',
                            filter_value: this.filter_status.value
                        });
                }

                const filter_query = {};

                if (this.filter_status)
                    filter_query.status = this.filter_status.value;

                if (this.filter_verification)
                    filter_query.verification = this.filter_verification.value;

                // set page to 1
                if (resetPage) {
                    this.serverParams.page = 1;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: 1});
                    this.$router.push({query: {...this.$route.query, ...filter_query, page: 1}});
                }

                if (filters.length)
                    this.updateParams({
                        filters: filters
                    });
                else this.removeParam('filters');

                if (retrieve)
                    this.retrieveProperties();
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveProperties();

                this.$router.push({query: {...this.$route.query, page: params.currentPage}});
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.retrieveProperties();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm, retrieve = true, resetPage = true) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                if (resetPage) {
                    this.serverParams.page = 1;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: 1});
                    this.$router.push({query: {...this.$route.query, term: this.searchTerm, page: 1}});
                }

                if(retrieve)
                    this.retrieveProperties();
            },
            async retrieveProperties() {
                this.is_loading_properties = true;

                this.$axios.get('properties', {params: this.serverParams})
                    .then(({data}) => {
                        this.properties = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_properties = false;

                        for (let p = 0; p < this.properties.length; p++) {
                            let availableDate = null;
                            availableDate = this.properties[p].attributes.available_at;

                            this.availableDates.push(availableDate);
                        }

                        console.log(this.properties)
                    })
                    .catch(e => {
                        this.is_loading_properties = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },

        mounted() {
            if (this.$route.query) {
                if (this.$route.query.page) {
                    this.serverParams.page = this.$route.query.page;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: +this.$route.query.page});
                }

                if (this.$route.query.verification)
                    this.filter_verification = this.filter_verification_options.find(o => o.value === this.$route.query.verification);

                if (this.$route.query.status)
                    this.filter_status = this.filter_status_options.find(o => o.value === this.$route.query.status);

                if (this.$route.query.term) {
                    this.searchTerm = this.$route.query.term;
                    this.search(this.$route.query.term, false, false);
                }
            }

            this.filterUpdated(false, false);

            this.retrieveProperties();
        },
        watch:{
            $route (){
                this.search(this.$route.query.term, true, false);
            }
        },
        head() {
            return {
                title: {
                    inner: this.$t('nav.properties')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        .headbar-expanded-container {
            @apply mt-4 flex flex-col gap-y-4 items-end;

            @screen md {
                @apply hidden;
            }
        }

        .btn-search-mobile, .btn-filter-mobile {
            @apply block;

            @screen md {
                @apply hidden;
            }

            &:active, &:focus {
                @apply outline-none;
            }
        }

        .search-desktop, .filter-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }

        .filter-mobile {

            @screen md{
                @apply max-w-xs;
            }
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }

        .td-status {
            .bulb {
                @apply w-4 h-4 bg-black rounded-full ml-5;

                &.available {
                    @apply bg-available;
                }

                &.unavailable {
                    @apply bg-unavailable;
                }

                &.pending {
                    @apply bg-pending;
                }

                &.upcoming {
                    @apply bg-upcoming;
                }
            }
        }
    }
</style>
