<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasPermission('store roles')"
                        className="--primary --small" :onclick="toggleCreate">
                    {{$t('roles.add_role')}}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('roles.search_roles')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only" @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                  <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                  <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
              <div class="headbar-expanded-container">
                <Search v-if="headbarExpanded === 'search'" :placeholder="$t('roles.search_roles')" @search="search"/>
              </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="roles"
                    :key="vgt_key"
                    :isLoading.sync="is_loading_roles"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('roles.roles')}),
            }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'permissions'">
                        <p>{{props.row.permissions}}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <!--<Button className="&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>-->
                        <Button v-if="$store.getters.hasPermission('destroy roles')"
                                className="--secondary --outline --mini --big-text" :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Button from "../../components/Button";
    import Search from "../../components/Search";
    import RolesCreateModal from "../../components/roles/RolesCreateModal";
    import ConfirmModal from "../../components/modal/ConfirmModal";

    export default {
        name: "roles-index-page",
        components: {ConfirmModal, Search, Button, Headbar},
        data() {
            return {
                columns: [
                    {
                        label: this.$t('roles.name'),
                        field: 'attributes.name',
                        sortable: false,
                    },
                    {
                        label: this.$t('roles.permissions'),
                        field: 'permissions',
                        sortable: false,
                    },
                    {
                        label: this.$t('roles.actions'),
                        field: 'after',
                        tdClass: 'td-after',
                        sortable: false
                    }
                ],
                roles: [],
                vgt_key: 0,
                is_loading_roles: false,
                is_deleting: false,
                totalRecords: null,
                serverParams: {},
                searchTerm: null,
                headbarExpanded: null
            }
        },
        methods: {
            toggleDelete(role) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('users.prompt_delete'),
                        subtext: this.$t('users.prompt_delete_subtext'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`roles/${role.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.retrieveRoles();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('roles.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            toggleCreate() {
                this.$modal.show(
                    RolesCreateModal, {},
                    {
                        name: 'roles-create-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveRoles();
                        }
                    }
                );
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveRoles();
            },
            onPerPageChange(params) {
                this.updateParams({per_page: params.currentPerPage, page: 1});
                this.retrieveRoles();
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.retrieveRoles();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm, retrieve = true, resetPage = true) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                if (resetPage) {
                    this.serverParams.page = 1;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: 1});
                    this.$router.push({query: {...this.$route.query, term: this.searchTerm, page: 1}});
                }

                if(retrieve)
                    this.retrieveRoles();
            },
            retrieveRoles() {
                this.is_loading_roles = true;

                this.$axios.get('roles', {params: this.serverParams})
                    .then(({data}) => {
                        this.roles = data.data;

                        this.roles.forEach(role => {
                            this.$axios.get(`roles/${role.id}/permissions`)
                                .then(({data}) => {
                                    role.permissions = data.data;
                                    this.vgt_key++;

                                    let permissions_array = [];

                                    role.permissions.forEach(p => {
                                        permissions_array.push(p.attributes.name)
                                    })

                                    role.permissions = permissions_array.toString();
                                })
                                .catch(e => {

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('permissions.error_retrieve')),
                                        type: 'error',
                                    });
                                });
                        });

                        this.is_loading_roles = false;

                        this.totalRecords = data.meta.total;
                    })
                    .catch(e => {
                        this.is_loading_roles = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },
        mounted() {
            if (this.$route.query) {
                if (this.$route.query.page) {
                    this.serverParams.page = this.$route.query.page;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: +this.$route.query.page});
                }

                if (this.$route.query.term) {
                    this.searchTerm = this.$route.query.term;
                    this.search(this.$route.query.term, false, false);
                }
            }

            this.retrieveRoles();
        },
        watch:{
            $route (){
                this.search(this.$route.query.term, true, false);
            }
        },
    }
</script>

<style lang="scss" scoped>
.page-container{
  .headbar-expanded-container {
    @apply mt-4 flex flex-col gap-y-4 items-end;

    @screen md {
      @apply hidden;
    }
  }

  .btn-search-mobile, .btn-filter-mobile {
    @apply block;

    @screen md {
      @apply hidden;
    }

    &:active,&:focus{
      @apply outline-none;
    }
  }

  .search-desktop, .filter-desktop {
    @apply hidden;

    @screen md {
      @apply block;
    }
  }

  .filter-mobile {
    @apply max-w-xs;
  }
}
</style>