<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left >
                <Form class="date-form"
                      v-if="selected_report === 'rentz_direct_statistics' || selected_report === 'agent_statistics'"
                      @submit="()=>false" :disabled="is_generating">
                    <FormInputDateTime className="filter" v-model="$v.date.from.$model" identifier="date_from"
                                       :inline-input="true" :label="$t('reports.date_from')" :only-date="true"
                                       formatted="Do MMM YYYY" :placeholder="$t('reports.date_from')"
                                       :disabled="is_generating" :has-error="$v.date.from.$error"
                                       output-format="YYYY-MM-DD"></FormInputDateTime>
                    <FormInputDateTime className="filter" v-model="$v.date.to.$model" identifier="date_to"
                                       :inline-input="true" :label="$t('reports.date_to')" :only-date="true"
                                       formatted="Do MMM YYYY" :placeholder="$t('reports.date_to')"
                                       :disabled="is_generating" :has-error="$v.date.to.$error"
                                       output-format="YYYY-MM-DD"></FormInputDateTime>
                </Form>
            </template>
            <template v-slot:right>
                <Select v-if="selected_report === 'agent_statistics' && $store.getters.user && $store.getters.user.relationships.roles.data.filter(r => r.attributes.name.toLowerCase() === 'administrator').length === 1"
                        v-model="user" :options="userOptions"
                        :placeholder="$t('reports.select_agent')"
                        :custom-label="(row) => row.attributes.name" track-by="id" :disabled="is_generating"
                        className="filter"/>
            </template>
        </Headbar>
        <main>
            <div class="reports-row">
                <button v-for="report in reportOptions" class="report-button-wrapper"
                        :class="{selected: selected_report && report.identifier === selected_report}"
                        @click="selectReport(report.identifier)">
                    <div class="report-button">
                        <span>{{report.title}}</span>
                    </div>
                </button>
            </div>
            <div class="export-row">
                <h3>{{$t('reports.export_report')}}</h3>

                <div class="row">
                    <button v-for="type in typeOptions" class="type-button-wrapper"
                            :class="{selected: selected_type && type.name === selected_type.name, spinner: is_generating && selected_type && type.name === selected_type.name}"
                            @click="selectType(type)">
                        <div class="type-button">
                            <font-awesome-icon :icon="[type.icon.type, type.icon.name]"/>
                            <span>{{type.name}}</span>
                        </div>
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import FormInputDateTime from "../../components/form/FormInputDateTime";
    import Form from "../../components/form/Form";
    import {required} from 'vuelidate/lib/validators'
    import Select from "../../components/form/Select";

    export default {
        name: "Index",
        components: {Select, Form, FormInputDateTime, Headbar},
        data() {
            return {
                date: {
                    from: null,
                    to: null,
                },
                selected_report: null,
                selected_type: null,
                user: null,
                userOptions: [],
                reportOptions: [
                    {
                        identifier: 'rentz_direct_statistics',
                        title: this.$t('reports.rentz_direct_statistics')
                    },
                    {
                        identifier: 'agent_statistics',
                        title: this.$t('reports.agent_statistics')
                    }
                ],
                typeOptions: [
                    {
                        name: 'pdf',
                        icon: {
                            type: 'fal',
                            name: 'file-pdf',
                        }
                    },
                    /*{
                        name: 'excel',
                        icon: {
                            type: 'fal',
                            name: 'file-excel',
                        }
                    }*/
                ],
                is_generating: false,
                is_loading_users: false,
            }
        },
        validations: {
            date: {
                from: {required},
                to: {required}
            },
        },
        methods: {
            selectReport(report) {
                this.selected_report = report;
            },
            selectType(type) {
                this.selected_type = type;
                this.requestReport();
            },
            requestReport() {
                if (this.selected_report === null || this.selected_type === null)
                    return;

                if (this.selected_report === 'agent_statistics') {
                    if (!this.user || !this.date.from || !this.date.to) {
                        this.$notify({
                            text: this.$t('reports.select_date_and_agent'),
                            type: 'error',
                        });

                        return;
                    }

                    this.is_generating = true;
                    this.$axios.post('users/agent-statistics', {
                        agent_id: this.user.id,
                        date_from: this.date.from,
                        date_to: this.date.to,
                    }, {
                        responseType: 'arraybuffer',
                        headers: {
                            'Accept': 'application/pdf'
                        }
                    }).then(({data}) => {
                        this.blobToPdf(data, 'agent_statistics');
                        this.is_generating = false;
                    }).catch(e => {
                        this.is_generating = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                            type: 'error',
                        });
                    });
                } else if (this.selected_report === 'rentz_direct_statistics') {
                    if (!this.date.from || !this.date.to) {
                        this.$notify({
                            text: this.$t('reports.select_date'),
                            type: 'error',
                        });

                        return;
                    }

                    this.is_generating = true;
                    this.$axios.post(`users/rentz-statistics`, {
                        date_from: this.date.from,
                        date_to: this.date.to,
                    }, {
                        responseType: 'arraybuffer',
                        headers: {
                            'Accept': 'application/pdf'
                        }
                    }).then(({data}) => {
                        this.blobToPdf(data, 'rentz_direct_statistics');
                        this.is_generating = false;
                    }).catch(e => {
                        this.is_generating = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                            type: 'error',
                        });
                    });
                }
            },
            blobToPdf(data, filename) {
                const blob = new Blob([data], {type: 'application/pdf'});
                const url = window.URL.createObjectURL(blob);

                const tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = url;
                tempLink.setAttribute('download', filename);
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }

                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
            },
            retrieveUsers() {
                this.is_loading_users = true;

                this.$axios.get('users/list')
                    .then(({data}) => {
                        this.userOptions = data.data;

                        const loggedUser = this.$store.getters.user;
                        const loggedUserRoles = loggedUser.relationships.roles.data;
                        if (loggedUserRoles.filter(r => r.attributes.name.toLowerCase() === 'administrator').length === 0) {
                            this.user = this.userOptions.find(u => u.id === loggedUser.id);
                        }

                        this.is_loading_users = false;
                    })
                    .catch(e => {
                        this.is_loading_users = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        mounted() {
            this.retrieveUsers();

            if (this.typeOptions.length)
                this.selected_type = this.typeOptions[0];
        },

        head() {
            return {
                title: {
                    inner: this.$t('nav.reports')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {

        .headbar-container{
            @apply flex flex-col space-y-4 items-start;

            @screen lg{
                @apply flex-row space-y-0 items-baseline w-full;
            }

                .date-form {
                    @apply flex flex-col bg-transparent space-y-4;

                    @screen sm{
                        @apply flex-row space-y-0;
                    }

                    & > * {
                        @apply mx-0 w-72;

                        @screen sm{
                            @apply mx-3;
                        }


                        &:first-child {
                            @apply ml-0;
                        }

                        &:last-child {
                            @apply mr-0;
                        }
                    }

                    .input-group {
                        @apply mb-0;
                    }
                }
            }

        main {
            .reports-row {
                @apply flex flex-row flex-wrap -mx-2 mb-24;

                button.report-button-wrapper {
                    @apply p-2 w-full;

                    @screen md {
                      @apply w-1/2;
                    }

                    @screen lg {
                      @apply w-1/3;
                    }

                    @screen lg {
                      @apply w-1/4;
                    }

                    @screen xl {
                      @apply w-1/5;
                    }

                    .report-button {
                        @apply bg-grey-light rounded-xl p-8;

                        span {
                            @apply text-black font-bold text-xl;
                        }
                    }

                    &:active, &:focus {
                        @apply outline-none;
                    }

                    &:hover, &:focus {
                        .report-button {
                            @apply bg-primary-over;
                        }
                    }

                    &.selected {
                        .report-button {
                            @apply bg-primary;
                        }
                    }
                }
            }

            .export-row {
                @apply flex flex-col items-center;

                h3 {
                    @apply text-black font-bold text-center text-2xl;
                }

                .row {
                    @apply mt-10 flex flex-row justify-center flex-wrap -mx-2;

                    button.type-button-wrapper {
                        @apply p-4;

                        .type-button {
                            @apply bg-grey-light rounded-xl p-4 flex flex-row items-center;

                            svg {
                                @apply text-black text-5xl;
                            }

                            span {
                                @apply text-black font-bold text-xl ml-4;
                            }
                        }

                        &:active, &:focus {
                            @apply outline-none;
                        }

                        &:hover, &:focus {
                            .type-button {
                                @apply bg-primary-over;
                            }
                        }

                        &.selected {
                            .type-button {
                                @apply bg-primary;
                            }
                        }
                    }
                }
            }
        }
    }
</style>