<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasPermission('store tenants')"
                        className="--primary --small" :onclick="toggleCreate">
                    {{$t('tenants.add_tenant')}}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('tenants.search_tenants')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only" @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('tenants.search_tenants')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table striped vgt-custom"
                :columns="columns"
                :rows="tenants"
                :isLoading.sync="is_loading_tenants"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    setCurrentPage: 1,
                    perPage: 15,
                    perPageDropdown: [15],
                    perPageDropdownEnabled: false,
                    dropdownAllowAll: false,
                    rowsPerPageLabel: this.$t('x_per_page', {x: this.$t('tenants.tenants')}),
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button v-if="$store.getters.hasPermission('update tenants')"
                                className="--secondary --outline --mini --big-text" :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('destroy tenants')"
                                className="--secondary --outline --mini --big-text" :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Search from "../../components/Search";
    import Button from "../../components/Button";
    import Select from "../../components/form/Select";
    import ConfirmModal from "../../components/modal/ConfirmModal";
    import TenantsCreateModal from "../../components/tenants/TenantsCreateModal";
    import TenantsUpdateModal from "../../components/tenants/TenantsUpdateModal";

    export default {
        name: "tenants-index-page",
        components: {ConfirmModal, Select, Button, Search, Headbar},
        data: function () {
            const columns = [
                {
                    label: this.$t('tenants.name'),
                    field: 'attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('tenants.email'),
                    field: 'attributes.email',
                    sortable: false,
                },
                {
                    label: this.$t('tenants.phone'),
                    field: 'attributes.phone',
                    sortable: false,
                },
                {
                    label: this.$t('tenants.property_type'),
                    field: 'attributes.property_type',
                    sortable: false,
                    formatFn: this.propertyType,
                },
                {
                    label: this.$t('tenants.budget'),
                    field: 'attributes.budget',
                    sortable: false,
                },
                {
                    label: this.$t('tenants.nationality'),
                    field: 'attributes.nationality',
                    sortable: false,
                },
                {
                    label: this.$t('tenants.occupation'),
                    field: 'attributes.occupation',
                    sortable: false,
                },
            ];

            if (this.$store.getters.hasAnyPermission(['update tenants', 'destroy tenants']))
                columns.push({
                    label: this.$t('tenants.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                });

            return {
                columns: columns,
                tenants: [],
                is_loading_tenants: false,
                totalRecords: null,
                serverParams: {},
                searchTerm: null,
                is_deleting: false,
                headbarExpanded: null,
                is_loading_type_options: false,
                typeOptions: []
            }
        },
        methods: {
            propertyType(value) {
                let type;

                this.typeOptions.forEach(option => {
                    if(value === option.column)
                        type = option.name;
                });

                return type;
            },
            toggleDelete(tenant) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('tenants.prompt_delete'),
                        subtext: this.$t('tenants.prompt_delete_subtext'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`tenants/${tenant.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.retrieveTenants();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('tenants.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            toggleCreate() {
                this.$modal.show(
                    TenantsCreateModal, {},
                    {
                        name: 'tenants-create-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveTenants();
                        }
                    }
                );
            },
            toggleUpdate(tenant) {
                this.$modal.show(
                    TenantsUpdateModal, {
                        tenant_id: tenant.id,
                    },
                    {
                        name: 'tenants-update-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveTenants();
                        }
                    }
                );
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveTenants();
            },
            onPerPageChange(params) {
                this.updateParams({per_page: params.currentPerPage, page: 1});
                this.retrieveTenants();
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.retrieveTenants();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm, retrieve = true, resetPage = true) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                if (resetPage) {
                    this.serverParams.page = 1;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: 1});
                    this.$router.push({query: {...this.$route.query, term: this.searchTerm, page: 1}});
                }

                if(retrieve)
                    this.retrieveTenants();
            },
            retrieveTenants() {
                this.is_loading_tenants = true;

                this.$axios.get('tenants', {params: this.serverParams})
                    .then(({data}) => {
                        this.tenants = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_tenants = false;
                    })
                    .catch(e => {
                        this.is_loading_tenants = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('tenants.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveTypeOptions() {
                this.is_loading_type_options = false;
                this.$axios.get('properties/list-types')
                    .then(({data}) => {
                        this.is_loading_type_options = false;
                        this.typeOptions = data;
                    })
                    .catch(e => {
                        this.is_loading_type_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('properties.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        mounted() {
            if (this.$route.query) {
                if (this.$route.query.page) {
                    this.serverParams.page = this.$route.query.page;
                    this.paginationOptions = Object.assign({}, this.paginationOptions, {setCurrentPage: +this.$route.query.page});
                }

                if (this.$route.query.term) {
                    this.searchTerm = this.$route.query.term;
                    this.search(this.$route.query.term, false, false);
                }
            }

            this.retrieveTenants();
            this.retrieveTypeOptions();
        },
        watch:{
            $route (){
                this.search(this.$route.query.term, true, false);
            }
        },
        head() {
            return {
                title: {
                    inner: this.$t('nav.tenants')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        .headbar-expanded-container {
            @apply mt-4 flex flex-col gap-y-4 items-end;

            @screen md {
                @apply hidden;
            }
        }

        .btn-search-mobile, .btn-filter-mobile {
            @apply block;

            @screen md {
                @apply hidden;
            }

            &:active,&:focus{
                @apply outline-none;
            }
        }

        .search-desktop, .filter-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }

        .filter-mobile {
            @apply max-w-xs;
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>
