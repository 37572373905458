<template>
    <div class="rent-create-modal">
        <ModalContainer :title="$t('properties.add_rent')" identifier="rent-create-modal" :closable="true">
            <Form @submit="save" class="form" :disabled="is_saving">
                <FormInputSelect v-model="$v.client.$model" identifier="client_name"
                                 :label="$t('properties.full_name')"
                                 :options="clientOptions" track-by="id"
                                 display-label="name" @tag="addClient" :taggable="true"
                                 :placeholder="$t('properties.full_name')" :disabled="is_saving">
                </FormInputSelect>
                <FormInputText v-if="client" identifier="client_phone" v-model="$v.client.phone.$model"
                               :label="$t('properties.contact_number')"
                               :placeholder="$t('properties.contact_number')" :disabled="is_saving">
                </FormInputText>
                <FormGroupTwo>
                    <FormInputDateTime identifier="starts_at" :label="$t('properties.starts_at')"
                                       :only-date="true" formatted="Do MMM YYYY" v-model="$v.starts_at.$model"
                                       :placeholder="$t('properties.starts_at')"
                                       :disabled="is_saving" output-format="YYYY-MM-DD">
                    </FormInputDateTime>
                    <FormInputDateTime identifier="ends_at" :label="$t('properties.ends_at')"
                                       v-model="$v.ends_at.$model"
                                       :only-date="true" formatted="Do MMM YYYY" :placeholder="$t('properties.ends_at')"
                                       :disabled="is_saving" output-format="YYYY-MM-DD">
                    </FormInputDateTime>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputDateTime identifier="expires_at" :label="$t('properties.deposit_expires_at')"
                                       :only-date="true" formatted="Do MMM YYYY" v-model="$v.expires_at.$model"
                                       :placeholder="$t('properties.expires_at')"
                                       :disabled="is_saving" output-format="YYYY-MM-DD">
                    </FormInputDateTime>
                </FormGroupTwo>
                <div class="upload-row">
                    <div class="upload-container">
                        <label v-if="attachment" for="rent-create-file-upload" class="has-attachment">
                            <p>{{attachment.name}}</p>
                            <button @click.prevent="attachment = null">
                                <font-awesome-icon :icon="['far','times']"/>
                            </button>
                        </label>
                        <label v-else for="rent-create-file-upload">
                            <font-awesome-icon :icon="['fal','upload']"/>
                            <p>{{$t('upload')}}</p>
                        </label>
                        <input type="file" name="file" id="rent-create-file-upload" accept="image/*,application/pdf"
                               @change="handleFileChange"
                               :disabled="is_saving"/>
                    </div>
                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{$t('save')}}
                    </Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputDateTime from "../form/FormInputDateTime";
    import Button from "../Button";
    import FormInputSelect from "../form/FormInputSelect";
    import {required} from 'vuelidate/lib/validators'

    export default {
        name: "RentCreateModal",
        components: {FormInputSelect, Button, FormInputDateTime, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            property_id: {
                required: true,
            }
        },
        data() {
            return {
                client: null,
                clientOptions: [],
                starts_at: null,
                ends_at: null,
                expires_at: null,
                attachment: null,
                is_loading_clients: false,
                is_saving: false,
            }
        },
        validations: {
            client: {
                name: {required},
                phone: {required}
            },
            starts_at: {
                required
            },
            ends_at: {
                required
            },
            expires_at: {
                required
            },
            attachment: {
                required
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('rent-create-modal', status);
            },
            addClient(newClient) {
                this.client = {
                    id: 0,
                    name: newClient,
                    phone: null,
                };
            },
            save() {
                this.$v.$touch();
                if (this.$v.$anyError || this.is_saving)
                    return;

                let formData = new FormData();

                if (this.client.id)
                    formData.append('client_id', this.client.id);
                else {
                    formData.append('clients[name]', this.client.name);
                    formData.append('clients[phone]', this.client.phone);
                }
                formData.append('contract_type', 'primary');
                formData.append('starts_at', this.starts_at);
                formData.append('ends_at', this.ends_at);
                formData.append('expires_at', this.expires_at);
                formData.append('name', this.attachment.name);
                formData.append('document', this.attachment);

                this.$axios.post(`properties/${this.property_id}/rents`, formData).then(({data}) => {
                    this.$notify({
                        text: this.$t('properties.success_rent_created'),
                        type: 'success',
                    });

                    this.close(true);

                    this.is_saving = false;
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('properties.error_rent_created')),
                        type: 'error',
                    });
                });
            },
            handleFileChange(e) {
                const files = e.target.files;

                if (files.length) {
                    this.attachment = files[0];
                } else {
                    this.attachment = null;
                }
            },
            retrieveClients() {
                this.is_loading_clients = true;
                this.$axios.get('clients')
                    .then(({data}) => {
                        this.clientOptions = data.data.map(c => ({
                            id: c.id,
                            name: c.attributes.name,
                            phone: c.attributes.phone
                        }));

                        this.is_loading_clients = false;
                    })
                    .catch(e => {
                        this.is_loading_clients = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('clients.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },

        mounted() {
            this.retrieveClients();
        }
    }
</script>

<style lang="scss" scoped>
    .upload-row {
        @apply w-full flex flex-row items-center justify-between;

        .upload-container {
            label {
                @apply py-3 px-5 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                svg {
                    @apply text-black text-lg;
                }

                p {
                    @apply text-black font-bold text-xs ml-3;
                }

                &.has-attachment {
                    @apply py-2;

                    p {
                        @apply ml-0 mr-3;
                    }

                    button {
                        @apply p-0 m-0 mt-1;
                    }
                }
            }

            input[type="file"] {
                @apply hidden;
            }
        }
    }
</style>