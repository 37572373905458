<template>
    <multiselect :class="className" :value="value" :options="options" v-bind="labelProps"
                 :placeholder="placeholder" :multiple="multiple" :showLabels="false" :closeOnSelect="closeOnSelect"
                 :allow-empty="allowEmpty" @input="(v)=>$emit('input', v) && $emit('updated')" :disabled="disabled"
                 :taggable="taggable" @tag="(newTag)=>$emit('tag', newTag)" :track-by="trackBy">
    </multiselect>
</template>

<script>
    export default {
        name: "Select",
        props: {
            value: {
                type: [String, Object, Array],
            },
            options: {
                type: Array,
                default: () => ([])
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: function () {
                    return this.$t('select');
                }
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            closeOnSelect: {
                type: Boolean,
                default: true,
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            label: {
                type: String,
            },
            customLabel: {
                default: null,
            },
            trackBy: {
                type: String,
            },
            className: {
                type: String,
            },
            taggable: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            labelProps() {
                if (this.customLabel)
                    return {customLabel: this.customLabel};
                else if (this.label)
                    return {label: this.label};
                else return {};
            }
        },
    }
</script>

<style scoped>

</style>