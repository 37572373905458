var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('Headbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [(_vm.$store.getters.hasPermission('store attributes'))?_c('Button',{attrs:{"className":"--primary --small","onclick":_vm.toggleCreate}},[_vm._v(" "+_vm._s(_vm.$t('attributes.add_attribute'))+" ")]):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('Search',{staticClass:"search-desktop",attrs:{"placeholder":_vm.$t('attributes.search_attributes')},on:{"search":_vm.search}}),_c('button',{staticClass:"btn-search-mobile btn-icon-only",on:{"click":function($event){_vm.headbarExpanded = _vm.headbarExpanded === 'search' ? null : 'search'}}},[(_vm.headbarExpanded === 'search')?_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}}):_c('font-awesome-icon',{attrs:{"icon":['far', 'search']}})],1)]},proxy:true},{key:"expanded",fn:function(){return [_c('div',{staticClass:"headbar-expanded-container"},[(_vm.headbarExpanded === 'search')?_c('Search',{attrs:{"placeholder":_vm.$t('attributes.search_attributes')},on:{"search":_vm.search}}):_vm._e()],1)]},proxy:true}])}),_c('main',[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table striped vgt-custom","columns":_vm.columns,"rows":_vm.attributes,"isLoading":_vm.is_loading_attributes,"search-options":{
                enabled: false,
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                setCurrentPage: 1,
                perPage: 15,
                perPageDropdown: [15],
                perPageDropdownEnabled: false,
                dropdownAllowAll: false,
                rowsPerPageLabel: this.$t('x_per_page', {x: this.$t('attributes.attributes')}),
            },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_attributes=$event},"update:is-loading":function($event){_vm.is_loading_attributes=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'date')?_c('div'):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(_vm.$store.getters.hasAnyPermission(['read attributes', 'update attributes']))?_c('router-link',{attrs:{"to":{name: 'attributes-update', params: {id: props.row.id}}}},[_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1)],1):_vm._e(),(_vm.$store.getters.hasAnyPermission(['destroy attributes']))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.toggleDelete(props.row); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }